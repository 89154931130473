import React, { useState, useEffect, useRef, Fragment } from "react";
import SmartZeeLogo from "../images/smartzee_logo.svg";
import BaseButton from "../components/BaseButton/BaseButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useMediaQuery } from "react-responsive";
import baseURL from "../baseURL";
import "./pages.css";

const Verify = (props) => {
  const [verifyStatus, setVerifyStatus] = useState("loading");
  const [resMsg, setResMsg] = useState("");
  // Reset password
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pwInputType, setPwInputType] = useState("password");

  const loginBtn = useRef(null);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1280 });

  const SITE_KEY = "6Lei5xsfAAAAAOJ8LN47N0XTDMVFGio3eg47mDCL";

  useEffect(() => {
    setTimeout(() => {
      if (props.URLType === "signupVerify") {
        verifyNewEmail();
      } else if (props.URLType === "emailChange") {
        verifyChangedEmail();
      }
    }, 3000);
  }, []);

  const verifyNewEmail = async () => {
    try {
      const url = new URL(
        `./api/v1/accounts/${props.URLPath}/?code=${props.URLCode}`,
        baseURL
      );

      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        setVerifyStatus("error");
        setResMsg("Error verifying your email.");
      } else {
        let data = await response.json();
        setVerifyStatus("success");
        setResMsg(
          "Email verified! You may now close this window. Please make sure that your account is activated so you are able to login to SmartZee"
        );
      }
    } catch (error) {
      setVerifyStatus("error");
      setResMsg("Error verifying your email.");
      console.log(error);
    }
  };

  const verifyChangedEmail = async () => {
    try {
      const url = new URL(
        `./api/v1/accounts/${props.URLPath}/?code=${props.URLCode}`,
        baseURL
      );

      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        setVerifyStatus("error");
        setResMsg("Error verifying your email.");
      } else {
        // let data = await response.json();
        setVerifyStatus("success");
        setResMsg(
          "Email verified! You may now close this window and login to SmartZee using your new email"
        );
      }
    } catch (error) {
      setVerifyStatus("error");
      setResMsg("Error verifying your new email.");
      console.log(error);
    }
  };

  const pwResetGET = async () => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const url = new URL(
        `./api/v1/accounts/password/reset/verify/?code=${props.URLCode}`,
        baseURL
      );

      const response = await fetch(url, {
        method: "GET",
      });

      let result = await response.json();

      if (!response.ok) {
        setVerifyStatus("error");
        result.detail && setResMsg(result.detail);
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
          setResMsg("");
        }, 3000);
      } else {
        getFreshToken();
      }
    } catch (error) {
      setVerifyStatus("error");
      console.log(error);
    }
  };

  const getFreshToken = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "password_reset_verified" })
        .then((accessToken) => {
          pwResetPOST(accessToken);
        });
    });
  };

  const pwResetPOST = async (accessToken) => {
    try {
      const url = new URL("./api/v1/accounts/password/reset/verified", baseURL);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: props.URLCode,
          password: password,
          recaptcha: accessToken,
        }),
      });

      if (!response.ok) {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setVerifyStatus("error");

        let result = await response.json();
        const breakText = (arr) =>
          arr.map((item, index) => (
            <span key={index}>
              {item && <br />}
              {item}
            </span>
          ));

        if (result.password) {
          let message = result.password[0];
          let messageArr = message.split("|");
          const lines = breakText(messageArr);
          setResMsg(lines);
        } else {
          setResMsg("Failed to save new password!");
        }
        setTimeout(() => {
          setError(false);
          setResMsg("");
        }, 5000);
      } else {
        // let data = await response.json();
        setLoading(false);
        setSuccess(true);
        setVerifyStatus("success");
        setResMsg(
          "Success! You may now close this window and login with your new password."
        );
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      setVerifyStatus("error");
      setResMsg("Failed to save new password!");
      setTimeout(() => {
        setError(false);
        setResMsg("");
      }, 3000);
      console.log(error);
    }
  };

  const handleKeyboardSubmit = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      console.log("Enter clicked!");
      console.log(loginBtn);
      loginBtn.current.click();
    }
  };

  const handlePwOnChange = (e) => {
    setResMsg("");
    setPassword(e.target.value);
  };

  const handleShowPw = () => {
    if (pwInputType === "password") {
      setPwInputType("text");
    } else {
      setPwInputType("password");
    }
  };

  return (
    <div className='verify-wrapper'>
      <div className='navbar'>
        <div className='logo'>
          <img src={SmartZeeLogo} alt='logo' />
        </div>
      </div>
      <div className='verify-content'>
        {props.URLType === "signupVerify" ? (
          <div className={verifyStatus === "loading" && "verify-loading"}>
            Verifying your email
          </div>
        ) : props.URLType === "emailChange" ? (
          <div className={verifyStatus === "loading" && "verify-loading"}>
            Email change verification
          </div>
        ) : props.URLType === "passwordReset" && !success ? (
          <div id='pw-reset-wrapper'>
            <div className='field is-horizontal auth-pw label-on-top'>
              <div
                className='field-label pt-3 pb-2'
                style={{
                  flexBasis: "auto",
                  flexGrow: "unset",
                }}>
                <label className='label has-text-weight-normal'>
                  Password
                  <span>
                    <OverlayTrigger
                      trigger='click'
                      key='right'
                      placement={isTablet ? "bottom" : "right"}
                      overlay={
                        <Popover id='info-popover'>
                          <Popover.Header as='h3'>Your Password</Popover.Header>
                          <Popover.Body>
                            Please create a non numeric password that at least
                            contains letters only or a mix of letters and
                            numbers, with a min length of 8 characters and a max
                            length of 20 characters. You may use whitespace and
                            special characters.
                          </Popover.Body>
                        </Popover>
                      }
                      rootClose>
                      <span className='info-circle'>i</span>
                    </OverlayTrigger>
                  </span>
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control is-expanded'>
                    <input
                      className={"input " + (error && "is-danger")}
                      type={pwInputType}
                      maxLength='20'
                      onChange={handlePwOnChange}
                      onKeyUp={(e) => handleKeyboardSubmit(e)}
                      value={password}
                      onPaste={(e) => {
                        if (pwInputType === "password") {
                          e.preventDefault();
                          setResMsg("Please write your password!");
                        }
                      }}
                    />
                    <svg
                      width='38'
                      height='38'
                      viewBox='0 0 38 38'
                      fill='none'
                      className='eye-icon'
                      alt='Show password'
                      onClick={handleShowPw}
                      xmlns='http://www.w3.org/2000/svg'>
                      <rect
                        width='38'
                        height='38'
                        viewBox='0 0 38 38'
                        x='0'
                        y='-10'
                        fill='#fff'></rect>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z'
                        fill={
                          pwInputType === "password" ? "#757575" : "#1DE9B6"
                        }
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal login-button-wrapper'>
              <div className='field pt-3'>
                <p className='control is-expanded'>
                  <BaseButton
                    classes='px-6 has-text-weight-semibold '
                    onClick={pwResetGET}
                    loading={loading}
                    success={success}
                    error={error}
                    disabled={password.length < 8}
                    text='SUBMIT NEW PASSWORD'
                    loginBtn={loginBtn}
                  />
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div
          style={{
            marginTop: "1rem",
            fontSize: "14px",
            color: verifyStatus === "success" ? "limegreen" : "#e52f2f",
          }}>
          {resMsg}
        </div>
      </div>
    </div>
  );
};

export default Verify;
