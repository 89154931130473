const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer
        className='footer'
        style={{
          position: "fixed",
          bottom: "0",
          height: "40px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "100000",
        }}>
        <div className='content has-text-centered flex-it'>
          <span>
            Copyrights &copy; {currentYear}&nbsp;
            <a
              href='https://smartzee.com/'
              target='_blank'
              style={{ textDecoration: "none" }}>
              SmartZee
            </a>
            &nbsp;Germany. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
