const NotFound404 = () => {
  return (
    <div
      className='flex-it'
      style={{
        height: "calc(100vh - 80px)",
        fontFamily: "Montserrat EB Italic, sans-serif",
        fontSize: "26px",
      }}>
      404 Page Not Found!
    </div>
  );
};

export default NotFound404;
