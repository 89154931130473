import React from "react";
import formatValue from "../../../utils/formatValue";

const TotalEnergyCardContent = (props) => {
  const chartLabels = ["Import", "Export", "PV Output"];

  // Assign CSS id based on received id prop
  const assignCSSId = (prop) => {
    switch (prop) {
      case "Today":
        return "today";
      case "Yesterday":
        return "yesterday";
      case "Chart0":
        return "last-week";
      case "Chart1":
        return "last-month";
      case "Chart2":
        return "last-year";
      case "Chart3":
        return "all";
    }
  };

  return (
    <div className='level is-mobile'>
      <div id={`Energy${props.id}`} className='ct-chart'></div>
      <div className='level-item rows mgr-small energy-reading-wrapper'>
        <h6
          id={`dash-energy-${assignCSSId(props.id)}-card-import-reading`}
          className='is-size-4-mobile is-size-5-tablet is-size-4-widescreen energy-reading'
          style={{ color: "#2196F3" }}>
          <span>{chartLabels[0]}</span>
          {formatValue(props.grid, "Wh") || "0"}
        </h6>
        <h6
          id={`dash-energy-${assignCSSId(props.id)}-card-export-reading`}
          className='is-size-4-mobile is-size-5-tablet is-size-4-widescreen energy-reading'
          style={{ color: "#1DE9B6" }}>
          <span>{chartLabels[1]}</span>
          {formatValue(props.pv, "Wh") || "0"}
        </h6>
        {props.dashboardType === "ZEE" && (
          <h6
            id={`dash-energy-${assignCSSId(props.id)}-card-pv-reading`}
            className='is-size-4-mobile is-size-5-tablet is-size-4-widescreen energy-reading'
            style={{ color: "#F2BF2B" }}>
            <span>{chartLabels[2]}</span>
            {formatValue(props.pvOutput, "Wh") || "0"}
          </h6>
        )}
      </div>
    </div>
  );
};

export default TotalEnergyCardContent;
