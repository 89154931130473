import React, { useState, useEffect, Fragment } from "react";
import BaseButton from "../BaseButton/BaseButton";
import SmartZeeIcon from "../../images/smartzee_icon.png";
import verticalDotsIcon from "../../images/mobile_vertical_dots.svg";
import "./CardView.css";

const CardView = (props) => {
  const [appBlurred, setAppBlurred] = useState(false);
  const [appBlurredCS, setAppBlurredCS] = useState(false);

  // Blur card view on run

  useEffect(() => {
    if (props.cardViewShown) {
      setTimeout(() => {
        setAppBlurred(true);
      }, 500);
      setTimeout(() => {
        setAppBlurredCS(true);
      }, 3000);
    }
  }, [props.cardViewShown]);

  const renderedCards =
    props.deviceListData &&
    props.deviceListData.map((obj, index) => {
      return (
        <div
          key={index}
          id='device-card-col'
          className='col-sm m-4 flex-center'>
          <div id='device_card' className='card' style={{ color: "#333" }}>
            <img
              src={verticalDotsIcon}
              alt='dots'
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
            <span className='device-img-container'>
              <img height={140} src={SmartZeeIcon} />
            </span>
            <div className='device-readings'>{obj.name}</div>
            <BaseButton
              classes='btn card-details-btn'
              text='Details'
              onClick={() => console.log("Clicked!")}
            />
          </div>
        </div>
      );
    });

  const renderedVirtCards =
    props.virtualDeviceList &&
    props.virtualDeviceList.map((obj, index) => {
      return (
        <div
          key={index}
          id='device-card-col'
          className='col-sm m-4 flex-center'>
          <div id='device_card' className='card' style={{ color: "#333" }}>
            <img
              src={verticalDotsIcon}
              alt='dots'
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
            <span className='device-img-container'>
              <img height={140} src={SmartZeeIcon} />
            </span>
            <div className='device-readings'>Standalone Smart Meters</div>
            <BaseButton
              classes='btn card-details-btn'
              text='Details'
              onClick={() => console.log("Clicked!")}
            />
          </div>
        </div>
      );
    });

  return renderedCards.length !== 0 ||
    (props.virtualDeviceList.length !== 0 &&
      props.virtualDeviceList[0].smartmeters.length !== 0) ? (
    <Fragment>
      <div
        id='device-card-container'
        className={`container ${appBlurred ? "app-blurred" : null}`}>
        <div
          id='device-card-row'
          className='row'
          style={{ paddingTop: "1rem" }}>
          {renderedCards}
          {renderedVirtCards}
        </div>
      </div>
      <span className={`cards-coming-soon ${appBlurredCS ? "show" : null}`}>
        Coming Soon..!
      </span>
    </Fragment>
  ) : (
    <div className='fail-message'>No available devices.</div>
  );
};

export default CardView;
