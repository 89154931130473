const validateHexHelper = (hex) => {
  return (
    typeof hex === "string" && hex.length === 12 && !isNaN(Number("0x" + hex))
  );
};

const validateLabelHelper = (val, valSetter, errSetter) => {
  if (val.length > 20) {
    valSetter(false);
    errSetter("Label must be less than 20 characters");
  } else if (val.length < 1) {
    valSetter(false);
    errSetter("Label must not be empty");
  } else {
    valSetter(true);
  }
};

const validateSNHelper = (val, valSetter, errSetter) => {
  if (validateHexHelper(val)) {
    valSetter(true);
  } else {
    valSetter(false);
    errSetter("Please enter a 12 character hex string");
  }
};

const handleKeyboardSubmitHelper = (e, ref) => {
  if (e.code === "Enter" || e.code === "NumpadEnter") {
    console.log("Enter clicked!");
    console.log(ref);
    ref.current.click();
  }
};

export { validateHexHelper, validateLabelHelper, validateSNHelper };
