import { useState, useEffect, Fragment } from "react";
import PowerConfig from "../PowerConfig/PowerConfig";
import VoltageConfig from "../VoltageConfig/VoltageConfig";
import FreqConfig from "../FreqConfig/FreqConfig";
import TimeConfig from "../TimeConfig/TimeConfig";
import { useMediaQuery } from "react-responsive";
import power from "./power.svg";
import time from "./time.svg";
import voltage from "./voltage.svg";
import frequency from "./frequency.svg";
import "./Tabbar.css";

const Tabbar = (props) => {
  const [tab, setTab] = useState("power");

  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const isSmallMobile = useMediaQuery({
    query: `(max-width: 500px)`,
  });

  useEffect(() => {
    setTab(props.activeMode);
  }, [props.activeMode]);

  // Refresh time table after save changes
  // useEffect(() => {
  //   if (props.isSaved && tab === "time") {
  //     setTab("power");
  //     setTimeout(() => {
  //       setTab("time");
  //     }, 100);
  //   }
  // }, [props.isSaved]);

  const handleChangeRadio = (e) => {
    setTab(e.target.value);
  };

  return (
    <div id='tabbar' style={{ flexDirection: "column" }}>
      <div className='tabs is-boxed'>
        {!isSmallMobile ? (
          <ul id='settings-tabs'>
            <li className={tab === "power" ? "is-active" : ""}>
              <a onClick={() => setTab("power")}>
                <div className='control '>
                  <label className='radio is-uppercase lighter-text'>
                    <input
                      id='config-power-tabbar-radio-input-desktop'
                      className='mr-5 my-2 custom-radio'
                      type='radio'
                      name='configmode'
                      value='power'
                      checked={tab === "power"}
                      onChange={handleChangeRadio}
                    />
                    POWER
                    <div className='circle'></div>
                  </label>
                </div>
              </a>
            </li>
            <li className={tab === "time" ? "is-active" : ""}>
              <a onClick={() => setTab("time")}>
                <div className='control '>
                  <label className='radio is-uppercase lighter-text'>
                    <input
                      id='config-time-tabbar-radio-input-desktop'
                      className='mr-5 my-2 custom-radio'
                      type='radio'
                      name='configmode'
                      value='time'
                      checked={tab === "time"}
                      onChange={handleChangeRadio}
                    />
                    TIME
                    <div className='circle'></div>
                  </label>
                </div>
              </a>
            </li>
            <li className={tab === "voltage" ? "is-active" : ""}>
              <a onClick={() => setTab("voltage")}>
                <div className='control '>
                  <label className='radio is-uppercase lighter-text'>
                    <input
                      id='config-voltage-tabbar-radio-input-desktop'
                      className='mr-5 my-2 custom-radio'
                      type='radio'
                      name='configmode'
                      value='voltage'
                      checked={tab === "voltage"}
                      onChange={handleChangeRadio}
                    />
                    VOLTAGE
                    <div className='circle'></div>
                  </label>
                </div>
              </a>
            </li>
            <li className={tab === "frequency" ? "is-active" : ""}>
              <a onClick={() => setTab("frequency")}>
                <div className='control '>
                  <label className='radio is-uppercase lighter-text'>
                    <input
                      id='config-frequency-tabbar-radio-input-desktop'
                      className='mr-5 my-2 custom-radio'
                      type='radio'
                      name='configmode'
                      value='frequency'
                      checked={tab === "frequency"}
                      onChange={handleChangeRadio}
                    />
                    FREQUENCY
                    <div className='circle'></div>
                  </label>
                </div>
              </a>
            </li>
          </ul>
        ) : (
          <ul id='settings-tabs'>
            <li className={tab === "power" ? "is-active" : ""}>
              <a
                id='config-power-tabbar-tab-mobile'
                onClick={() => setTab("power")}>
                <span className='icon'>
                  <img src={power} alt='power icon' />
                </span>
                <span className='name'>POWER</span>
              </a>
            </li>
            <li className={tab === "time" ? "is-active" : ""}>
              <a
                id='config-time-tabbar-tab-mobile'
                onClick={() => setTab("time")}>
                <span className='icon'>
                  <img src={time} alt='time icon' />
                </span>
                <span className='name'>TIME</span>
              </a>
            </li>
            <li className={tab === "voltage" ? "is-active" : ""}>
              <a
                id='config-voltage-tabbar-tab-mobile'
                onClick={() => setTab("voltage")}>
                <span className='icon'>
                  <img
                    src={voltage}
                    alt='voltage icon'
                    className='voltage-icon'
                  />
                </span>
                <span className='name'>VOLTAGE</span>
              </a>
            </li>
            <li className={tab === "frequency" ? "is-active" : ""}>
              <a
                id='config-frequency-tabbar-tab-mobile'
                onClick={() => setTab("frequency")}>
                <span className='icon '>
                  <img src={frequency} alt='frequency icon' />
                </span>
                <span className='name'>FREQUENCY</span>
              </a>
            </li>
          </ul>
        )}
      </div>
      <div className='tab-area'>
        {tab === "power" ? (
          <PowerConfig
            remote={props.remote}
            setRemote={props.setRemote}
            activeMode={props.activeMode}
            setActiveMode={props.setActiveMode}
            initPowerSP={props.initPowerSP}
            powerSP={props.powerSP}
            getPowerConfig={props.getPowerConfig}
            isSubmit={props.isSubmit}
            setSubmitIsDisabled={props.setSubmitIsDisabled}
            mapping={props.mapping}
          />
        ) : tab === "time" ? (
          <TimeConfig
            remote={props.remote}
            setRemote={props.setRemote}
            activeMode={props.activeMode}
            setActiveMode={props.setActiveMode}
            initTimeTable={props.initTimeTable}
            timeTable={props.timeTable}
            getTimeConfig={props.getTimeConfig}
            isSubmit={props.isSubmit}
            setSubmitIsDisabled={props.setSubmitIsDisabled}
            showMobileModal={props.showMobileModal}
            setShowMobileModal={props.setShowMobileModal}
            repeatDaysModal={props.repeatDaysModal}
            setRepeatDaysModal={props.setRepeatDaysModal}
            repeatDaysRecIndexModal={props.repeatDaysRecIndexModal}
            setRepeatDaysRecIndexModal={props.setRepeatDaysRecIndexModal}
            alterEditedRowsArr={props.alterEditedRowsArr}
          />
        ) : tab === "voltage" ? (
          <VoltageConfig
            remote={props.remote}
            setRemote={props.setRemote}
            activeMode={props.activeMode}
            setActiveMode={props.setActiveMode}
            voltageSP={props.voltageSP}
            voltageMin={props.voltageMin}
            voltageMax={props.voltageMax}
            initVoltageSP={props.initVoltageSP}
            initVoltageMin={props.initVoltageMin}
            initVoltageMax={props.initVoltageMax}
            getVoltageConfig={props.getVoltageConfig}
            isSubmit={props.isSubmit}
            setSubmitIsDisabled={props.setSubmitIsDisabled}
            mapping={props.mapping}
          />
        ) : tab === "frequency" ? (
          <FreqConfig
            remote={props.remote}
            setRemote={props.setRemote}
            activeMode={props.activeMode}
            setActiveMode={props.setActiveMode}
            freqSP={props.freqSP}
            freqMin={props.freqMin}
            freqMax={props.freqMax}
            initFreqSP={props.initFreqSP}
            initFreqMin={props.initFreqMin}
            initFreqMax={props.initFreqMax}
            getFreqConfig={props.getFreqConfig}
            isSubmit={props.isSubmit}
            setSubmitIsDisabled={props.setSubmitIsDisabled}
            mapping={props.mapping}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Tabbar;
