import React, { useState, useEffect, Fragment } from "react";
import BaseButton from "../BaseButton/BaseButton";
import MobileModalCloseIcon from "../../images/mobile_modal_close.svg";
import "./MobileModal.css";

const MobileModal = (props) => {
  const [render, setRender] = useState("-900px" || props.showMobileModal);

  useEffect(() => {
    if (props.showMobileModal) {
      setRender(true);
    }
  }, [props.showMobileModal]);

  const cancelSelect = () => {
    props.setShowMobileModal(false);
  };

  const onAnimationEnd = () => {
    if (!props.showMobileModal) {
      setRender(false);
    }
  };

  return (
    <Fragment>
      <div
        id='mobile-anim-wrapper'
        style={{
          bottom: props.showMobileModal ? "0" : "-900px",
          zIndex: "10000000000 !important",
        }}
        onAnimationEnd={onAnimationEnd}>
        <div className='card-controls-box new-mobile card-controls-box-mobile dark'>
          <header className='card-controls-header'>
            <p className='card-controls-title'>{props.title}</p>
            <div
              className='controls-btn'
              onClick={() => {
                cancelSelect();
                // cancelFlag means closing modal from top right (x) button just closes it
                // but canceling from cancel action button does some action while closing
                !props.cancelFlag
                  ? props.handleCloseModal()
                  : props.cancelFilters();
              }}>
              <div className='card-controls-box-btn card-controls-box-btn-cancel'>
                <img
                  id='mobile-modal-close-btn'
                  src={MobileModalCloseIcon}
                  alt='close'
                />
              </div>
            </div>
          </header>
          <div className='card-controls-content card-controls-content-mobile'>
            {props.content}
          </div>
          <div className='card-controls-footer card-controls-footer-mobile'>
            <footer
              className='modal-card-foot'
              style={{ justifyContent: "flex-end" }}>
              <BaseButton
                id={props.actionBtnId}
                classes='button save'
                onClick={props.actionFn}
                text={props.actionName || "Save"}
                loading={props.loading}
                error={props.error}
                success={props.success}
                disabled={props.disabled}
                textColor='#fff'
              />
              <button
                id={props.cancelBtnId}
                className='button'
                onClick={() => {
                  cancelSelect();
                  props.handleCloseModal();
                }}>
                {props.cancelName || "Cancel"}
              </button>
            </footer>
            <p
              id='mobile-modal-err-msg'
              style={{
                marginBottom: "0",
                fontSize: "11px",
                color: "#f03a5f",
                minHeight: "16px",
                textAlign: "left",
                visibility: props.errMsg ? "visible" : "hidden",
                color: props.isSuccess ? "limegreen" : "red",
              }}>
              {props.errMsg}
            </p>
          </div>
        </div>
      </div>
      <div
        id='mobile-modal-background'
        style={{
          backgroundColor: "rgb(30,39,51,0.86)",
          display: props.showMobileModal ? "block" : "none",
        }}></div>
    </Fragment>
  );
};

export default MobileModal;
