import React, { useState, useEffect, Fragment } from "react";
import ListView from "../components/ListView/ListView";
import CardView from "../components/CardView/CardView";
import AddDevice from "../components/AddDevice/AddDevice";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "../components/Modal/Modal";
import Footer from "../components/Footer/Footer";
import { useHistory } from "react-router-dom";
import baseURL from "../baseURL";
import "./pages.css";

const Home = (props) => {
  const [viewType, setViewType] = useState("list");
  const [allDeviceListData, setAllDeviceListData] = useState([]);
  const [deviceListData, setDeviceListData] = useState([]);
  const [smetersListData, setSmetersListData] = useState([]);
  const [SMLoading, setSMLoading] = useState(false);
  const [SMLoadingErr, setSMLoadingErr] = useState(false);
  const [SMLoadedId, setSMLoadedId] = useState(null);
  const [fetchError, setFetchError] = useState(true);
  const [errMessage, setErrMessage] = useState(
    "Failed to get device data. Please refresh page to try again."
  );
  const [isConfig, setIsConfig] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [dashboardType, setDashboardType] = useState("ZEE");
  const [isLoading, setIsLoading] = useState(true);
  const [animExit, setAnimExit] = useState(true);
  const [cardViewShown, setCardViewShown] = useState(false);
  const [submitErrMsg, setSubmitErrMsg] = useState("");
  const [deviceListStatusArr, setDeviceListStatusArr] = useState([]);
  const [SMListStatusArr, setSMListStatusArr] = useState([]);
  const [retryWSConn, setRetryWSConn] = useState(false);
  const [closeDeviceWS, setCloseDeviceWS] = useState(false);
  const [virtualDeviceId, setVirtualDeviceId] = useState(null);
  const [virtualDeviceList, setVirtualDeviceList] = useState([]);
  const [openZeeId, setOpenZeeId] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isVeriscribed, setIsVeriscribed] = useState(true);
  const [remotePriceId, setRemotePriceId] = useState(undefined);
  const [remotePriceVal, setRemotePriceVal] = useState(undefined);
  const [remotePriceRes, setRemotePriceRes] = useState(undefined);

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  const history = useHistory();

  useEffect(() => {
    if (props.loaded === true) {
      getDeviceList();
    }
    if (props.loaded === false) {
      setIsLoading(false);
      setFetchError(true);
    }
  }, [props.loaded]);

  useEffect(() => {
    if (props.userUnAuthorized === true) {
      setIsLoading(false);
      setErrMessage("Incorrect Authorization! Please login again.");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      setTimeout(() => {
        props.setAuthenticated(false);
        props.setUserUnauthorized(false);
        props.setLoaded(null);
      }, 2500);
    }
  }, [props.userUnAuthorized]);

  useEffect(() => {
    if (!isDashboard) {
      sessionStorage.removeItem("powerInterval");
      sessionStorage.removeItem("energyInterval");
      sessionStorage.removeItem("energyGroup");
      sessionStorage.removeItem("powerLegendImport");
      sessionStorage.removeItem("powerLegendExport");
      sessionStorage.removeItem("powerLegendPV");
      sessionStorage.removeItem("powerBGFill");
      sessionStorage.removeItem("energyLegendImport");
      sessionStorage.removeItem("energyLegendExport");
      sessionStorage.removeItem("energyLegendPV");
    }
  }, [isDashboard]);

  useEffect(() => {
    let ws;
    // Open the WebSocket connection using the JWT.
    const openWsConnection = (jwtAuth) => {
      // If a connection already exists, close it.
      ws && ws.close();

      let locationHost =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? baseURL.replace(/^https?:\/\//, "")
          : baseURL.host;

      ws = new WebSocket(
        `${
          window.location.protocol === "http:" ? "ws" : "wss"
        }://${locationHost}/ws/devices/?token=${jwtAuth}`
      );

      let deviceIdListArr = deviceListData.map((obj) => obj.id);
      let SMArrofArrs = allDeviceListData.map((obj) => obj.smartmeters);
      let SMIdListArr = [].concat.apply([], SMArrofArrs);

      let msg = {
        type: "heartbeat",
        devices: deviceIdListArr,
        smartmeters: SMIdListArr,
      };

      let onlineDeviceListArr = [];
      let onlineSMListArr = [];
      let isFirstRun = true;

      // Send a message whenever the WebSocket connection opens.
      let openWS = (ws.onopen = (event) => {
        console.log("WebSocket connection is opened.");
        onlineDeviceListArr = [];
        onlineSMListArr = [];
        // Send message
        ws.send(JSON.stringify(msg));
        // Set data everytime after messages are received
        if (isFirstRun) {
          setTimeout(() => {
            setDeviceListStatusArr(onlineDeviceListArr);
            setSMListStatusArr(onlineSMListArr);
          }, 3000);
          isFirstRun = false;
        } else {
          setTimeout(() => {
            setDeviceListStatusArr(onlineDeviceListArr);
            setSMListStatusArr(onlineSMListArr);
          }, 5000);
        }
        // // Remote Price
        // if (remotePriceId) {
        //   console.log("🚀 ~ openWS ~ remotePriceId:", remotePriceId);
        //   let msg = {
        //     type: "control",
        //     smartmeters: [remotePriceId],
        //     con_val: "1",
        //   };
        //   ws.send(JSON.stringify(msg));
        //   console.log("🚀 ~ openWsConnection ~ msg:", msg);
        // }
      });

      let sendData = !closeDeviceWS && setInterval(openWS, 60000);

      // Display any new messages received in the `messageDiv`.
      ws.onmessage = (event) => {
        console.log("WebSocket message received: ", event.data);

        let dataObj = JSON.parse(event.data);
        console.log(dataObj);
        let devId = dataObj.Device;
        let SMId = dataObj.SM;

        if (devId !== undefined) {
          // Add new device then remove duplicates
          onlineDeviceListArr = [...new Set([...onlineDeviceListArr, devId])];
        }
        if (SMId !== undefined) {
          // Add new device then remove duplicates
          onlineSMListArr = [...new Set([...onlineSMListArr, SMId])];
        }

        console.log("Online Devices: ", onlineDeviceListArr);
        console.log("Online SMs: ", onlineSMListArr);
      };

      ws.onerror = (event) => {
        console.log("WebSocket error received: ", event);
        setTimeout(() => {
          setRetryWSConn(!retryWSConn);
        }, 1000);
      };

      ws.onclose = (event) => {
        console.log("WebSocket connection closed.");
        clearInterval(sendData);
        // Resetting these flags and states after every connection close will lead OFFLINE to appear momentarily after every add/edit/delete
        // onlineDeviceListArr = [];
        // onlineSMListArr = [];
        //   setDeviceListStatusArr([]);
        //   setSMListStatusArr([]);
        setRetryWSConn(true);
      };
    };

    if (
      allDeviceListData &&
      allDeviceListData.length !== 0 &&
      !closeDeviceWS &&
      props.userIsVerified &&
      props.userIsSubscribed
    ) {
      openWsConnection(token);
    }
    return () => ws && ws.close();
  }, [
    deviceListData,
    allDeviceListData,
    retryWSConn,
    closeDeviceWS,
    props.userIsVerified,
    props.userIsSubscribed,
  ]);

  useEffect(() => {
    let ws2;
    // If a connection already exists, close it.
    !remotePriceId && ws2 && ws2.close();

    // Open the WebSocket connection using the JWT.
    const openWsConnection = (jwtAuth) => {
      let locationHost =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? baseURL.replace(/^https?:\/\//, "")
          : baseURL.host;

      ws2 = new WebSocket(
        `${
          window.location.protocol === "http:" ? "ws" : "wss"
        }://${locationHost}/ws/devices/?token=${jwtAuth}`
      );

      if (remotePriceId) {
        ws2.onopen = (event) => {
          console.log("🚀 ~ openWS ~ remotePriceId:", remotePriceId);
          let msg = {
            type: "control",
            smartmeters: [remotePriceId],
            con_val: remotePriceVal,
          };
          ws2.send(JSON.stringify(msg));
          console.log("🚀 ~ openWsConnection ~ msg:", msg);
        };
      }

      // Display any new messages received in the `messageDiv`.
      ws2.onmessage = (event) => {
        console.log("WebSocket message received: ", event.data);
        setRemotePriceId(undefined);
        setRemotePriceRes(JSON.parse(event.data).message);
        setTimeout(() => {
          setRemotePriceRes(undefined);
        }, 1500);
        ws2.close();
      };

      ws2.onerror = (event) => {
        console.log("WebSocket error received: ", event);
      };

      ws2.onclose = (event) => {
        console.log("WebSocket connection closed.");
        ws2.close();
      };
    };

    remotePriceId && openWsConnection(token);
    return () => !remotePriceId && ws2 && ws2.close();
  }, [remotePriceId]);

  const getDeviceList = async () => {
    // No need to reset this flag as usual as error will force user to reload component, so it will by reset to false by default
    // setFetchError(false);
    // Setting this flag to true on every request run will cause loading anim to appear momentarily after every add/edit/delete
    // setIsLoading(true);
    try {
      const head = new Headers();
      head.append(
        "Authorization",
        "Bearer " +
          (localStorage.getItem("token") || sessionStorage.getItem("token"))
      );

      const url = new URL(
        `./api/v1/users/${props.userIdAdminOverride || userId}/devices`,
        baseURL
      );

      const response = await fetch(url, {
        method: "GET",
        headers: head,
      });

      if (response.ok) {
        const data = await response.json();
        setAllDeviceListData(data);
        let zeeList = data.filter(
          (obj) =>
            obj.serial_number !=
            parseInt(props.userIdAdminOverride || userId, 10)
        );
        let standaloneList = data.filter(
          (obj) =>
            obj.serial_number ==
            parseInt(props.userIdAdminOverride || userId, 10)
        );
        let virtDeviceId =
          standaloneList.length !== 0
            ? Number(standaloneList[0].id)
            : "no-virt-device";
        setVirtualDeviceId(virtDeviceId);
        setDeviceListData(zeeList);
        setVirtualDeviceList(standaloneList);
        setIsLoading(false);
        setFetchError(false);
      } else {
        setIsLoading(false);
        setFetchError(true);
        if (response.status === 403) {
          setIsVeriscribed(false);
          setErrMessage("");
        } else {
          setErrMessage("Incorrect Authorization! Please login again.");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          setTimeout(() => {
            props.setAuthenticated(false);
          }, 2500);
        }
      }
      // TODO
      // if res === 401
      // setErrMessage(data.error);
    } catch (error) {
      setDeviceListData([]);
      setFetchError(true);
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSMetersList = async (id) => {
    setSMLoading(true);
    setSMLoadingErr(false);

    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);

      const url = new URL(`./api/v1/devices/${id}/smartmeters`, baseURL);

      const response = await fetch(url, {
        method: "GET",
        headers: head,
      });

      if (!response.ok) {
        setSMLoading(false);
        setSMLoadingErr(true);
        throw new Error();
      } else {
        let data = await response.json();
        setSmetersListData(data);
        setSMLoading(false);
        setSMLoadedId(id);
      }
    } catch (error) {
      setSMLoading(false);
      setSMLoadingErr(true);
      console.log(error);
    }
  };

  return (
    <div
      id='homepage'
      style={{
        minHeight: "calc(100vh - 140px)",
        textAlign: "center",
        position: "relative",
      }}>
      <h6
        id='homepage-title'
        className='home-title'>{`${dashboardType} DEVICE ${
        isConfig ? "SETTINGS" : isDashboard ? "DASHBOARD" : "LIST"
      }`}</h6>
      <div className='change-view-wrapper'>
        <div
          className='change-view-title'
          style={{ display: (isConfig || isDashboard) && "none" }}>
          CHANGE VIEW
        </div>
        <Tabs
          activeKey={viewType}
          onSelect={(k) => {
            setViewType(k);
            if (k === "card") {
              setCardViewShown(true);
            } else {
              setCardViewShown(false);
            }
          }}>
          <Tab
            id='home-list-btn'
            eventKey='list'
            title={
              <svg
                width='40'
                height='28'
                viewBox='0 0 40 28'
                fill='none'
                style={{ display: (isConfig || isDashboard) && "none" }}
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M2.22217 2.88892H4.44439V5.11114H2.22217V2.88892Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M7.77786 5.11114H34.4445C34.7392 5.11114 35.0218 4.99408 35.2302 4.7857C35.4386 4.57733 35.5556 4.29471 35.5556 4.00003C35.5556 3.70534 35.4386 3.42273 35.2302 3.21435C35.0218 3.00598 34.7392 2.88892 34.4445 2.88892H7.77786C7.48317 2.88892 7.20056 3.00598 6.99219 3.21435C6.78381 3.42273 6.66675 3.70534 6.66675 4.00003C6.66675 4.29471 6.78381 4.57733 6.99219 4.7857C7.20056 4.99408 7.48317 5.11114 7.77786 5.11114Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M2.22217 9.55554H4.44439V11.7778H2.22217V9.55554Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M34.4445 9.55554H7.77786C7.48317 9.55554 7.20056 9.67261 6.99219 9.88098C6.78381 10.0894 6.66675 10.372 6.66675 10.6667C6.66675 10.9613 6.78381 11.244 6.99219 11.4523C7.20056 11.6607 7.48317 11.7778 7.77786 11.7778H34.4445C34.7392 11.7778 35.0218 11.6607 35.2302 11.4523C35.4386 11.244 35.5556 10.9613 35.5556 10.6667C35.5556 10.372 35.4386 10.0894 35.2302 9.88098C35.0218 9.67261 34.7392 9.55554 34.4445 9.55554Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M2.22217 16.2222H4.44439V18.4444H2.22217V16.2222Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M34.4445 16.2222H7.77786C7.48317 16.2222 7.20056 16.3392 6.99219 16.5476C6.78381 16.756 6.66675 17.0386 6.66675 17.3333C6.66675 17.628 6.78381 17.9106 6.99219 18.119C7.20056 18.3273 7.48317 18.4444 7.77786 18.4444H34.4445C34.7392 18.4444 35.0218 18.3273 35.2302 18.119C35.4386 17.9106 35.5556 17.628 35.5556 17.3333C35.5556 17.0386 35.4386 16.756 35.2302 16.5476C35.0218 16.3392 34.7392 16.2222 34.4445 16.2222Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M2.22217 22.8889H4.44439V25.1111H2.22217V22.8889Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M34.4445 22.8889H7.77786C7.48317 22.8889 7.20056 23.006 6.99219 23.2144C6.78381 23.4227 6.66675 23.7053 6.66675 24C6.66675 24.2947 6.78381 24.5773 6.99219 24.7857C7.20056 24.9941 7.48317 25.1111 7.77786 25.1111H34.4445C34.7392 25.1111 35.0218 24.9941 35.2302 24.7857C35.4386 24.5773 35.5556 24.2947 35.5556 24C35.5556 23.7053 35.4386 23.4227 35.2302 23.2144C35.0218 23.006 34.7392 22.8889 34.4445 22.8889Z'
                  fill='#A4A5AA'
                />
              </svg>
            }>
            {isLoading ? (
              <div className='spinner spinner--style5' title='Loading'>
                <svg
                  version='1.1'
                  id='Layer_1'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  x='0px'
                  y='0px'
                  width='24px'
                  height='30px'
                  viewBox='0 0 24 30'
                  style={{ enableBackground: "new 0 0 50 50" }}
                  xmlSpace='preserve'>
                  <rect x='0' y='0' width='4' height='10' fill='#333'>
                    <animateTransform
                      attributeType='xml'
                      attributeName='transform'
                      type='translate'
                      values='0 0; 0 20; 0 0'
                      begin='0'
                      dur='0.6s'
                      repeatCount='indefinite'
                    />
                  </rect>
                  <rect x='10' y='0' width='4' height='10' fill='#333'>
                    <animateTransform
                      attributeType='xml'
                      attributeName='transform'
                      type='translate'
                      values='0 0; 0 20; 0 0'
                      begin='0.2s'
                      dur='0.6s'
                      repeatCount='indefinite'
                    />
                  </rect>
                  <rect x='20' y='0' width='4' height='10' fill='#333'>
                    <animateTransform
                      attributeType='xml'
                      attributeName='transform'
                      type='translate'
                      values='0 0; 0 20; 0 0'
                      begin='0.4s'
                      dur='0.6s'
                      repeatCount='indefinite'
                    />
                  </rect>
                </svg>
              </div>
            ) : !fetchError &&
              deviceListData.length === 0 &&
              virtualDeviceList[0].smartmeters.length === 0 ? (
              <Fragment>
                <div
                  id='home-no-devices-msg'
                  className='fail-message'
                  style={{ marginBottom: "3rem" }}>
                  No available devices. You may add your first device via the
                  (&nbsp;+&nbsp;) button.
                </div>
                <AddDevice
                  deviceListData={deviceListData}
                  smetersListData={smetersListData}
                  getDeviceList={getDeviceList}
                  getSMetersList={getSMetersList}
                  userId={userId}
                  isMobile={props.isMobile}
                  submitErrMsg={submitErrMsg}
                  setSubmitErrMsg={setSubmitErrMsg}
                  userIdAdminOverride={props.userIdAdminOverride}
                  virtualDeviceId={virtualDeviceId}
                  setDeviceListData={setDeviceListData}
                  setSmetersListData={setSmetersListData}
                  openZeeId={openZeeId}
                  isSuccess={isSuccess}
                  setIsSuccess={setIsSuccess}
                />
              </Fragment>
            ) : fetchError && isVeriscribed ? (
              <div id='home-err-msg' className='fail-message'>
                {errMessage}
              </div>
            ) : props.userIsVerified === false ? (
              <div
                id='home-not-verified-msg'
                className='fail-message'
                style={{ marginBottom: "3rem" }}>
                Your account email{" "}
                <span style={{ color: "#3f9eff" }}>{props.userEmail}</span>{" "}
                needs to be verified.
                <br />
                Please check your inbox for the confirmation email. If you can't
                find it in your Inbox, please check Spam / Junk folder.
                <br />
                You may also navigate to{" "}
                <span
                  id='home-account-link'
                  style={{
                    color: "#3f9eff",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/account")}>
                  Account
                </span>{" "}
                page and click on <b>Resend verification email</b> icon to
                resend the confirmation email.
              </div>
            ) : props.userIsVerified === true &&
              props.userIsSubscribed === false ? (
              <div
                id='home-not-subscribed-msg'
                className='fail-message'
                style={{ marginBottom: "3rem" }}>
                Please subscribe to SmartZee by contacting website admin
                (admin@smartzee.com).
              </div>
            ) : !isVeriscribed ? (
              <div id='home-something-went-wrong-msg' className='fail-message'>
                Something went wrong! Please reload page!
              </div>
            ) : (
              <Fragment>
                <ListView
                  deviceListData={deviceListData}
                  setDeviceListData={setDeviceListData}
                  isConfig={isConfig}
                  setIsConfig={setIsConfig}
                  isDashboard={isDashboard}
                  setIsDashboard={setIsDashboard}
                  setDashboardType={setDashboardType}
                  getDeviceList={getDeviceList}
                  getSMetersList={getSMetersList}
                  SMLoading={SMLoading}
                  SMLoadingErr={SMLoadingErr}
                  SMLoadedId={SMLoadedId}
                  scrollTopRef={props.scrollTopRef}
                  setSmetersListData={setSmetersListData}
                  smetersListData={smetersListData}
                  setAnimExit={setAnimExit}
                  isMobile={props.isMobile}
                  isTablet={props.isTablet}
                  submitErrMsg={submitErrMsg}
                  setSubmitErrMsg={setSubmitErrMsg}
                  deviceListStatusArr={deviceListStatusArr}
                  SMListStatusArr={SMListStatusArr}
                  setCloseDeviceWS={setCloseDeviceWS}
                  userIdAdminOverride={props.userIdAdminOverride}
                  virtualDeviceList={virtualDeviceList}
                  virtualDeviceId={virtualDeviceId}
                  setOpenZeeId={setOpenZeeId}
                  setIsSuccess={setIsSuccess}
                  setRemotePriceId={setRemotePriceId}
                  setRemotePriceVal={setRemotePriceVal}
                  remotePriceRes={remotePriceRes}
                />
                {!isLoading &&
                  !fetchError &&
                  !isConfig &&
                  !isDashboard &&
                  animExit && (
                    <AddDevice
                      deviceListData={deviceListData}
                      smetersListData={smetersListData}
                      getDeviceList={getDeviceList}
                      getSMetersList={getSMetersList}
                      userId={userId}
                      isMobile={props.isMobile}
                      submitErrMsg={submitErrMsg}
                      setSubmitErrMsg={setSubmitErrMsg}
                      userIdAdminOverride={props.userIdAdminOverride}
                      virtualDeviceId={virtualDeviceId}
                      setDeviceListData={setDeviceListData}
                      setSmetersListData={setSmetersListData}
                      openZeeId={openZeeId}
                      isSuccess={isSuccess}
                      setIsSuccess={setIsSuccess}
                    />
                  )}
              </Fragment>
            )}
            {!props.isMobile && !isDashboard && !isConfig && <Footer />}
          </Tab>
          <Tab
            id='home-card-btn'
            eventKey='card'
            title={
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                style={{ display: (isConfig || isDashboard) && "none" }}
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M8 30H4C3.46957 30 2.96086 29.7893 2.58579 29.4142C2.21071 29.0391 2 28.5304 2 28V24C2 23.4696 2.21071 22.9609 2.58579 22.5858C2.96086 22.2107 3.46957 22 4 22H8C8.53043 22 9.03914 22.2107 9.41421 22.5858C9.78929 22.9609 10 23.4696 10 24V28C10 28.5304 9.78929 29.0391 9.41421 29.4142C9.03914 29.7893 8.53043 30 8 30ZM4 24V28H8V24H4Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M18 30H14C13.4696 30 12.9609 29.7893 12.5858 29.4142C12.2107 29.0391 12 28.5304 12 28V24C12 23.4696 12.2107 22.9609 12.5858 22.5858C12.9609 22.2107 13.4696 22 14 22H18C18.5304 22 19.0391 22.2107 19.4142 22.5858C19.7893 22.9609 20 23.4696 20 24V28C20 28.5304 19.7893 29.0391 19.4142 29.4142C19.0391 29.7893 18.5304 30 18 30ZM14 24V28H18V24H14Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M28 30H24C23.4696 30 22.9609 29.7893 22.5858 29.4142C22.2107 29.0391 22 28.5304 22 28V24C22 23.4696 22.2107 22.9609 22.5858 22.5858C22.9609 22.2107 23.4696 22 24 22H28C28.5304 22 29.0391 22.2107 29.4142 22.5858C29.7893 22.9609 30 23.4696 30 24V28C30 28.5304 29.7893 29.0391 29.4142 29.4142C29.0391 29.7893 28.5304 30 28 30ZM24 24V28H28V24H24Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M8 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V14C2 13.4696 2.21071 12.9609 2.58579 12.5858C2.96086 12.2107 3.46957 12 4 12H8C8.53043 12 9.03914 12.2107 9.41421 12.5858C9.78929 12.9609 10 13.4696 10 14V18C10 18.5304 9.78929 19.0391 9.41421 19.4142C9.03914 19.7893 8.53043 20 8 20ZM4 14V18H8V14H4Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M18 20H14C13.4696 20 12.9609 19.7893 12.5858 19.4142C12.2107 19.0391 12 18.5304 12 18V14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12H18C18.5304 12 19.0391 12.2107 19.4142 12.5858C19.7893 12.9609 20 13.4696 20 14V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20ZM14 14V18H18V14H14Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M28 20H24C23.4696 20 22.9609 19.7893 22.5858 19.4142C22.2107 19.0391 22 18.5304 22 18V14C22 13.4696 22.2107 12.9609 22.5858 12.5858C22.9609 12.2107 23.4696 12 24 12H28C28.5304 12 29.0391 12.2107 29.4142 12.5858C29.7893 12.9609 30 13.4696 30 14V18C30 18.5304 29.7893 19.0391 29.4142 19.4142C29.0391 19.7893 28.5304 20 28 20ZM24 14V18H28V14H24Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M8 10H4C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H8C8.53043 2 9.03914 2.21071 9.41421 2.58579C9.78929 2.96086 10 3.46957 10 4V8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10ZM4 4V8H8V4H4Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M18 10H14C13.4696 10 12.9609 9.78929 12.5858 9.41421C12.2107 9.03914 12 8.53043 12 8V4C12 3.46957 12.2107 2.96086 12.5858 2.58579C12.9609 2.21071 13.4696 2 14 2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4V8C20 8.53043 19.7893 9.03914 19.4142 9.41421C19.0391 9.78929 18.5304 10 18 10ZM14 4V8H18V4H14Z'
                  fill='#A4A5AA'
                />
                <path
                  d='M28 10H24C23.4696 10 22.9609 9.78929 22.5858 9.41421C22.2107 9.03914 22 8.53043 22 8V4C22 3.46957 22.2107 2.96086 22.5858 2.58579C22.9609 2.21071 23.4696 2 24 2H28C28.5304 2 29.0391 2.21071 29.4142 2.58579C29.7893 2.96086 30 3.46957 30 4V8C30 8.53043 29.7893 9.03914 29.4142 9.41421C29.0391 9.78929 28.5304 10 28 10ZM24 4V8H28V4H24Z'
                  fill='#A4A5AA'
                />
              </svg>
            }>
            <CardView
              deviceListData={deviceListData}
              virtualDeviceList={virtualDeviceList}
              setIsConfig={setIsConfig}
              cardViewShown={cardViewShown}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Home;
