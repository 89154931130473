import React, { Fragment } from "react";
import "./Dashboard.css";

const DashboardCard = (props) => {
  return (
    <div id='dash-card' className={`card ${props.classNames}`}>
      <header className='card-header'>
        <div className='upper-bg'></div>
        <div className='lower-bg'></div>
        <p className='card-header-title'>
          <span
            className='card-header-title-display-icon'
            style={{ display: props.displayIcon || "none" }}>
            {props.displayIcon}
          </span>
          {props.title}
        </p>
        <span
          className='card-header-action-icon'
          onClick={() => {
            props.setter(!props.fs);
            console.log("Setter run!");
          }}>
          {props.actionIcon}
        </span>
      </header>
      <div className='card-content'>
        <div className='content'>{props.content}</div>
      </div>
    </div>
  );
};

export default DashboardCard;
