import React, { useState, useEffect } from "react";
import Toggler from "../Toggler/Toggler";
import "./FreqConfig.css";

const FreqConfig = (props) => {
  const [freqSP, setFreqSP] = useState(null);
  const [freqMin, setFreqMin] = useState(null);
  const [freqMax, setFreqMax] = useState(null);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isErrorSP, setIsErrorSP] = useState(false);
  const [isErrorFmin, setIsErrorFmin] = useState(false);
  const [isErrorFmax, setIsErrorFmax] = useState(false);

  useEffect(() => {
    setMessage("");
    setIsError(false);
    setIsErrorSP(false);
    setIsErrorFmin(false);
    setIsErrorFmax(false);
    if (validateFreq(props.freqSP, props.freqMin, props.freqMax)) {
      setFreqSP(props.freqSP);
      setFreqMin(props.freqMin);
      setFreqMax(props.freqMax);
    } else {
      setFreqSP(props.initFreqSP);
      setFreqMin(props.initFreqMin);
      setFreqMax(props.initFreqMax);
    }
  }, [props.activeMode, props.isSubmit, props.mapping]);

  useEffect(() => {
    if (props.isSubmit) {
      props.getFreqConfig(freqSP, freqMin, freqMax);
    }
  }, [props.isSubmit]);

  const validateFreq = (sp, min, max) => {
    if (
      sp !== "" &&
      sp >= 0 &&
      sp <= 100 &&
      min >= 48 &&
      min <= 52 &&
      max >= 48 &&
      max <= 52 &&
      min < max
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeFreqSP = (e) => {
    let val = e.target.value.replace(/[^\d]+/gi, "");
    setFreqSP(val);
    props.getFreqConfig(val, freqMin, freqMax);

    if (val > 0 && val < 100) {
      setMessage("");
      setIsError(false);
      setIsErrorSP(false);
    } else if (val > 100) {
      setMessage("SP cannot be greater than 100%");
      setIsError(true);
      setIsErrorSP(true);
      props.setSubmitIsDisabled(true);
    } else if (val == 100) {
      setMessage("Feed-In to Grid Mode");
      setIsError(false);
      setIsErrorSP(false);
    } else if (val === "0") {
      setMessage("Zero Export Mode");
      setIsError(false);
      setIsErrorSP(false);
    } else if (val === "") {
      setMessage("");
      setIsError(true);
      props.setSubmitIsDisabled(true);
    }

    if (validateFreq(val, freqMin, freqMax)) {
      props.setSubmitIsDisabled(false);
      setIsError(false);
      setIsErrorSP(false);
    }
  };

  const handleSaveFreqSP = () => {
    if (validateFreq(freqSP, freqMin, freqMax)) {
      props.getFreqConfig(freqSP, freqMin, freqMax);
    } else {
      props.getFreqConfig(
        props.initFreqSP,
        props.initFreqMin,
        props.initFreqMax
      );
    }
  };

  const handleChangeFreqMin = (e) => {
    let val = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    let truncatedVal;
    truncatedVal =
      val.indexOf(".") >= 0 ? val.slice(0, val.indexOf(".") + 4) : val;

    setFreqMin(truncatedVal);
    props.getFreqConfig(freqSP, truncatedVal, freqMax);

    if (truncatedVal !== "") {
      if (truncatedVal >= 48 && truncatedVal <= 52) {
        setMessage("");
        setIsError(false);
        setIsErrorFmin(false);
      } else if (truncatedVal > 52) {
        setMessage("Fmin cannot be greater than 52Hz");
        setIsError(true);
        setIsErrorFmin(true);
        props.setSubmitIsDisabled(true);
      } else if (truncatedVal < 48) {
        setMessage("Fmin cannot be less than 48Hz");
        setIsError(true);
        setIsErrorFmin(true);
        props.setSubmitIsDisabled(true);
      }
      if (freqMax && truncatedVal >= freqMax) {
        setMessage("Fmin cannot be less than or equal Fmax!");
        setIsError(true);
        setIsErrorFmin(true);
        props.setSubmitIsDisabled(true);
      }
      if (validateFreq(freqSP, truncatedVal, freqMax)) {
        props.setSubmitIsDisabled(false);
        setIsError(false);
        setIsErrorFmin(false);
        setIsErrorFmax(false);
      }
    } else {
      setMessage("");
    }
  };

  const handleSaveFreqMin = () => {
    if (validateFreq(freqSP, freqMin, freqMax)) {
      props.getFreqConfig(freqSP, freqMin, freqMax);
    } else {
      props.getFreqConfig(
        props.initFreqSP,
        props.initFreqMin,
        props.initFreqMax
      );
    }
  };

  const handleChangeFreqMax = (e) => {
    let val = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    let truncatedVal;
    truncatedVal =
      val.indexOf(".") >= 0 ? val.slice(0, val.indexOf(".") + 4) : val;

    setFreqMax(truncatedVal);
    props.getFreqConfig(freqSP, freqMin, truncatedVal);

    if (truncatedVal !== "") {
      if (truncatedVal >= 48 && truncatedVal <= 52) {
        setMessage("");
        setIsError(false);
        setIsErrorFmax(false);
      } else if (truncatedVal > 52) {
        setMessage("Fmax cannot be greater than 52Hz");
        setIsError(true);
        setIsErrorFmax(true);
        props.setSubmitIsDisabled(true);
      } else if (truncatedVal < 48) {
        setMessage("Fmax cannot be less than 48Hz");
        setIsError(true);
        setIsErrorFmax(true);
        props.setSubmitIsDisabled(true);
      }
      if (freqMin && freqMin >= truncatedVal) {
        setMessage("Fmin cannot be less than or equal Fmax!");
        setIsError(true);
        setIsErrorFmax(true);
        props.setSubmitIsDisabled(true);
      }
      if (validateFreq(freqSP, freqMin, truncatedVal)) {
        props.setSubmitIsDisabled(false);
        setIsError(false);
        setIsErrorFmin(false);
        setIsErrorFmax(false);
      }
    } else {
      setMessage("");
    }
  };

  const handleSaveFreqMax = () => {
    if (validateFreq(freqSP, freqMin, freqMax)) {
      props.getFreqConfig(freqSP, freqMin, freqMax);
    } else {
      props.getFreqConfig(
        props.initFreqSP,
        props.initFreqMin,
        props.initFreqMax
      );
    }
  };

  return (
    <div className='sub-config freq-config'>
      <div className='columns'>
        <div className='column'>
          <div className='flex-start'>
            <label className='label lighter-text min-200'>
              FREQUENCY CONTROLLED EXPORT
            </label>
            <Toggler
              id='config-frequency-toggler'
              remote={props.remote}
              setRemote={props.setRemote}
              activeMode={props.activeMode}
              setActiveMode={props.setActiveMode}
              isActive={props.activeMode === "frequency" ? true : false}
              mode='frequency'
              setSubmitIsDisabled={props.setSubmitIsDisabled}
              isError={isError}
            />
          </div>
        </div>
      </div>
      <div className='columns mt-0 pt-0'>
        <div className='column mt-0 pt-0 pr-0'>
          <div className='title-card-wrapper my-2'>
            <div className='title-card flex-start'>
              FREQUENCY EXPORT SETTINGS
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='col-sm pb-0'>
          <div className='flex-start'>
            <label className='label lighter-text'>FMIN (Hz)</label>
            <input
              id='config-fmin-input'
              className={`input ${isErrorFmin ? "invalid-input" : ""}`}
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              onChange={handleChangeFreqMin}
              value={freqMin}
              onBlur={handleSaveFreqMin}
            />
          </div>
        </div>
        <div className='col-sm pb-0 fmax-desktop'>
          <div className='flex-end pb-0'>
            <label className='label lighter-text'>FMAX (Hz)</label>
            <input
              id='config-fmax-input-desktop'
              className={`input ${isErrorFmax ? "invalid-input" : ""}`}
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              onChange={handleChangeFreqMax}
              value={freqMax}
              onBlur={handleSaveFreqMax}
            />
          </div>
        </div>
      </div>
      <div className='row fsp-desktop'>
        <div className='flex-start'>
          <label className='label lighter-text'>SETPOINT (%)</label>
          <input
            id='config-frequency-setpoint-input-desktop'
            className={`input ${isErrorSP ? "invalid-input" : ""}`}
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            onChange={handleChangeFreqSP}
            value={freqSP}
            onBlur={handleSaveFreqSP}
          />
        </div>
      </div>
      <div className='row fmax-mobile'>
        <div className='flex-start'>
          <label className='label lighter-text'>FMAX (Hz)</label>
          <input
            id='config-fmax-input-mobile'
            className={`input ${isErrorFmax ? "invalid-input" : ""}`}
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            onChange={handleChangeFreqMax}
            value={freqMax}
            onBlur={handleSaveFreqMax}
          />
        </div>
      </div>
      <div className='row fsp-mobile'>
        <div className='flex-start'>
          <label className='label lighter-text'>SETPOINT (%)</label>
          <input
            id='config-frequency-setpoint-input-mobile'
            className='input'
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            onChange={handleChangeFreqSP}
            value={freqSP}
            onBlur={handleSaveFreqSP}
          />
        </div>
      </div>
      <p
        style={{
          color: isError ? "red" : "limegreen",
          fontWeight: "normal",
          textAlign: "left",
          minHeight: "1rem",
          position: "relative",
          top: "2rem",
        }}>
        {message}
      </p>
    </div>
  );
};

export default FreqConfig;
