import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Spinner } from "react-bootstrap";
import Toggler from "../Toggler/Toggler";
import TimeRow from "../TimeConfig/TimeRow";
import { useMediaQuery } from "react-responsive";
import plusIcon from "../../images/plus_icon.svg";
import "./TimeConfig.css";

const TimeConfig = (props) => {
  const [timeTable, setTimeTable] = useState([]);
  const [message, setMessage] = useState("");
  const [dimensions, setDimensions] = useState((1 / 1025) * window.innerWidth);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1025 });
  const isMobile = useMediaQuery({ minWidth: 531, maxWidth: 768 });
  const isSmlMobile = useMediaQuery({ maxWidth: 530 });

  useEffect(() => {
    console.log("isSubmit from Time comp:", props.isSubmit);
    if (props.isSubmit) {
      props.getTimeConfig(timeTable);
    }
  }, [props.isSubmit]);

  useEffect(() => {
    const tableScaleRatio = () => {
      setDimensions((1 / 1025) * window.innerWidth);
    };
    window.addEventListener("resize", tableScaleRatio);
    return (_) => {
      window.removeEventListener("resize", tableScaleRatio);
    };
  }, []);

  useEffect(() => {
    setTimeTable(props.timeTable);
    console.log(timeTable);
  }, [props.timeTable]);

  const getTimeRowData = (rowIndex, record) => {
    let new_timeTable = timeTable.map((obj, index) => {
      return index == rowIndex ? record : obj;
    });
    console.log(new_timeTable);
    props.getTimeConfig(new_timeTable);
    setTimeTable(new_timeTable);
  };

  const checkMidnightOverlap = (time) => {
    var dt = new Date(new Date().setHours(0, 0, 0, 0));
    let epochTimeMs = time * 1000;
    var d = new Date(epochTimeMs);
    const hrs = d.getHours(); //getUTCHours() for GMT time
    const mins = d.getMinutes(); //getUTCMinutes() for GMT time

    if (hrs + 2 < 24) {
      setMessage("");
      return 7200; // Add 2 hours to create end time
    } else {
      setMessage("");
      console.log((24 - hrs) * 60 + mins);
      return ((24 - hrs) * 60 - mins) * 60; // Add minutes to reach next day midnight create end time
    }
  };

  const deleteRow = (rowIndex) => {
    props.setSubmitIsDisabled(false);
    let new_timeTable = timeTable.filter((object, index) => {
      return index != rowIndex ? true : false;
    });
    console.log(new_timeTable);
    props.getTimeConfig(new_timeTable);
    setTimeTable(new_timeTable);
  };

  const renderedTable = timeTable.map((obj, index) => {
    const convertDate = (time) => {
      const epochTimeMs = time * 1000;
      const d = new Date(epochTimeMs);
      var strDate =
        d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
      return strDate;
    };

    const convertTime = (time) => {
      let epochTimeMs = time * 1000;
      var d = new Date(epochTimeMs);
      const hrs = d.getHours(); //getUTCHours() for GMT time
      const mins = d.getMinutes(); //getUTCMinutes() for GMT time
      let formattedTime =
        (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" + mins : mins);
      return formattedTime;
    };

    // For the sake of MUI time-picker that accepts time as full datetime
    const convertTimeAsDate = (time) => {
      let epochTimeMs = time * 1000;
      var dateFromEpoch = new Date(epochTimeMs);
      return dateFromEpoch;
    };

    let date = convertDate(obj.start_time);
    let start_time = convertTime(obj.start_time);
    let start_time_as_date = convertTimeAsDate(obj.start_time);
    let end_time = convertTime(obj.end_time);
    let end_time_as_date = convertTimeAsDate(obj.end_time);
    let setpoint = obj.setpoint;
    let repeat = obj.repeat;

    return (
      <TimeRow
        key={index}
        index={index}
        date={date}
        startTime={start_time}
        startTimeAsDate={start_time_as_date}
        endTime={end_time}
        endTimeAsDate={end_time_as_date}
        setpoint={setpoint}
        repeat={repeat}
        getTimeRowData={getTimeRowData}
        deleteRow={deleteRow}
        setSubmitIsDisabled={props.setSubmitIsDisabled}
        setMessage={setMessage}
        isMobile={isMobile}
        isSmlMobile={isSmlMobile}
        showMobileModal={props.showMobileModal}
        setShowMobileModal={props.setShowMobileModal}
        repeatDaysModal={props.repeatDaysModal}
        setRepeatDaysModal={props.setRepeatDaysModal}
        repeatDaysRecIndexModal={props.repeatDaysRecIndexModal}
        setRepeatDaysRecIndexModal={props.setRepeatDaysRecIndexModal}
        timeTable={timeTable}
        alterEditedRowsArr={props.alterEditedRowsArr}
      />
    );
  });

  const addRow = () => {
    console.log("Add time row clicked!");
    props.setSubmitIsDisabled(false);
    let new_timeTable = Object.assign({}, timeTable);
    let now = Math.floor(Date.now() / 1000); // 1631656740 to simulate 11:59PM - For real now: Date.now() / 1000
    console.log(now);
    new_timeTable = [
      ...timeTable,
      {
        start_time: now,
        end_time: now + checkMidnightOverlap(now),
        setpoint: 0,
        repeat: { flag: 0, days: [] },
      },
    ];
    console.log(new_timeTable);
    props.getTimeConfig(new_timeTable);
    setTimeTable(new_timeTable);
  };

  return (
    <div className='sub-config time-config'>
      <div className='columns'>
        <div className='column'>
          <div className='flex-start'>
            <label className='label lighter-text min-200'>
              TIME CONTROLLED EXPORT
            </label>
            <Toggler
              id='config-time-toggler'
              remote={props.remote}
              setRemote={props.setRemote}
              activeMode={props.activeMode}
              setActiveMode={props.setActiveMode}
              isActive={props.activeMode === "time" ? true : false}
              mode='time'
              setSubmitIsDisabled={props.setSubmitIsDisabled}
            />
          </div>
        </div>
      </div>
      <div className='columns mt-0 pt-0'>
        <div className='column mt-0 pt-0'>
          <div className='title-card-wrapper my-2'>
            <div className='title-card flex-start'>TIME EXPORT SETTINGS</div>
            <hr />
          </div>
        </div>
      </div>
      <div className='columns m-0 p-0'>
        <div
          className='column m-0 p-0 time_table_wrapper'
          style={{
            transform: `scale(${isTablet ? dimensions : "1.0"})`,
          }}>
          {timeTable && timeTable.length != 0 ? (
            !isMobile && !isSmlMobile ? (
              // Desktop Time Table Headers
              <div id='time-desktop' className='time_table'>
                <Table responsive='md'>
                  <tbody style={{ textAlign: "center" }}>
                    <tr>
                      <td className='table-header'>NO.</td>
                      <td className='table-header'>DATE</td>
                      <td className='table-header'>START TIME</td>
                      <td className='table-header'>END TIME</td>
                      <td className='table-header'>SP %</td>
                      <td className='table-header'>REPEAT</td>
                      <td className='table-header table-header-days'>
                        <tr className='table-header table-header-days-sub'>
                          DAYS
                        </tr>
                        <tr className='table-header table-header-days-sub'>
                          <td style={{ borderLeft: "none" }}>
                            <span className='table-header-day'>Mon</span>
                          </td>
                          <td>
                            <span className='table-header-day'>Tue</span>
                          </td>
                          <td>
                            <span className='table-header-day'>Wed</span>
                          </td>
                          <td>
                            <span className='table-header-day'>Thu</span>
                          </td>
                          <td>
                            <span className='table-header-day'>Fri</span>
                          </td>
                          <td>
                            <span className='table-header-day'>Sat</span>
                          </td>
                          <td style={{ borderRight: "none" }}>
                            <span className='table-header-day'>Sun</span>
                          </td>
                        </tr>
                      </td>
                      <td className='table-header'>EDIT</td>
                    </tr>
                    {renderedTable}
                  </tbody>
                </Table>
              </div>
            ) : isMobile ? (
              // Tab / Large Mobile Time Table Headers
              <div id='time-mobile' className='time_table time_table_mobile'>
                <Table responsive='sm'>
                  <tbody style={{ textAlign: "center" }}>
                    <tr>
                      <td className='table-header'>NO.</td>
                      <td className='table-header'>DATE / TIME</td>
                      <td className='table-header'>SP %</td>
                      <td className='table-header'>REPEAT</td>
                      <td className='table-header'>EDIT</td>
                    </tr>
                    {renderedTable}
                  </tbody>
                </Table>
              </div>
            ) : isSmlMobile ? (
              // Small Mobile Time Table Headers
              <div
                id='time-mobile'
                className='time_table time_table_sml_mobile'>
                <Table responsive='sm'>
                  <tbody style={{ textAlign: "center" }}>
                    <tr>
                      <td className='table-header'>NO.</td>
                      <td className='table-header'>DATE / TIME</td>
                      <td className='table-header'>SP %</td>
                      <td className='table-header'>REPEAT</td>
                    </tr>
                    {renderedTable}
                  </tbody>
                </Table>
              </div>
            ) : null
          ) : (
            "No Records"
          )}
          <p
            id='config-time-err-msg'
            style={{
              position: "absolute",
              fontWeight: "normal",
              color: "red",
              textAlign: "left",
            }}>
            {message}
          </p>
          <div className='add-wrapper add-wrapper-time'>
            <div className='add-circle-wrapper'>
              <span
                id='config-time-add-record-btn'
                className='add-circle-content flex-it'
                style={{
                  transform: `scale(${isTablet ? 1 / dimensions : "1.0"})`,
                }}
                onClick={
                  timeTable.length < 50
                    ? addRow
                    : () => {
                        setMessage(
                          "Timetable records cannot be greater than 50!"
                        );
                        setTimeout(() => {
                          setMessage("");
                        }, 3000);
                      }
                }>
                <img src={plusIcon} alt='add' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeConfig;
