import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  Fragment,
} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { KeyboardDatePicker } from "@material-ui/pickers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Modal from "../../Modal/Modal";
import submitArrow from "../../../images/dashboard/enter_arrow.svg";
import leftArrow from "../../../images/dashboard/arrow_left.svg";
import FFArrow from "../../../images/dashboard/arrow_ff.svg";
import baseURL from "../../../baseURL";
import "./EnergyChart.css";

am4core.useTheme(am4themes_animated);

const EnergyChart = (props) => {
  const [isRendered, setIsRendered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [startDateEpoch, setStartDateEpoch] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [endDateEpoch, setEndDateEpoch] = useState(0);
  const [rsMessage, setRsMessage] = useState("");
  const [activeInterval, setActiveInterval] = useState("W");
  const [activeGroupInterval, setActiveGroupInterval] = useState("day");
  const [isGrouped, setIsGrouped] = useState(true);
  const [showRS, setShowRS] = useState(false);
  // This current interval start will be the end interval on clicking arrow back
  const [curIntervalStart, setCurIntervalStart] = useState(null);
  // This current interval end will be the start interval on clicking arrow forward
  const [curIntervalEnd, setCurIntervalEnd] = useState(null);
  const [arrowContIsShown, setArrowContIsShown] = useState(true);
  const [arrowForwardIsDisabled, setArrowForwardIsDisabled] = useState(true);
  const [arrowBackIsDisabled, setArrowBackIsDisabled] = useState(false);
  const [arrowFFIsDisabled, setArrowFFIsDisabled] = useState(true);
  const [groupArr, setGroupArr] = useState(["hour", "day", "month"]);
  const [getDefaultGroup, setGetDefaultGroup] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [lastProfilStartDate, setLastProfilStartDate] = useState(null);
  const [lastProfilStartDateEpoch, setLastProfilStartDateEpoch] = useState(0);
  const [lastProfilEndDate, setLastProfilEndDate] = useState(null);
  const [lastProfilEndDateEpoch, setLastProfilEndDateEpoch] = useState(0);
  const [lastProfilMsg, setLastProfilMsg] = useState("");
  const [lastProfilFetchLoading, setLastProfilFetchLoading] = useState(false);
  const [lastProfilFetchSuccess, setLastProfilFetchSuccess] = useState(false);
  const [lastProfilFetchError, setLastProfilFetchError] = useState(false);
  const [lastProfilSameDayMsg, setLastProfilSameDayMsg] = useState("");

  const chartRef2 = useRef(null);
  const series4Ref = useRef(null);
  const series5Ref = useRef(null);
  const series6Ref = useRef(null);
  const selectorDiv = useRef(null);
  const chartContainerRef2 = useRef(null);

  useEffect(() => {
    console.log("<Energy /> comp rendered!");
  }, []);

  useEffect(() => {
    console.log("activeInterval changed in <Energy />!");
    setGetDefaultGroup(true);
  }, [activeInterval]);

  useEffect(() => {
    console.log("isGrouped changed in <Energy />!");
  }, [isGrouped]);

  useEffect(() => {
    console.log("props.data changed in <Energy />!");
  }, [props.data]);

  useEffect(() => {
    console.log(
      "🚀 ~ file: EnergyChart.js ~ line 69 ~ useEffect ~ energyChartLiveData",
      props.energyChartLiveData
    );
    activeInterval === "Live" &&
      chartRef2.current.addData(props.energyChartLiveData, 1);
  }, [props.energyChartLiveData]);

  useEffect(() => {
    let today = new Date();
    let todayEpoch = parseInt(today / 1000);
    let oneWeekBefore = new Date(new Date().setDate(today.getDate() - 6));
    let oneWeekBeforeEpoch = parseInt(oneWeekBefore / 1000);
    setCurIntervalStart(oneWeekBeforeEpoch);
    setCurIntervalEnd(todayEpoch);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("energyInterval")) {
      setActiveInterval(sessionStorage.getItem("energyInterval"));
      setActiveGroupInterval(sessionStorage.getItem("energyGroup"));
    }
  }, [props.fs]);

  useEffect(() => {
    activeInterval === "Live" ||
    activeInterval === "YTD" ||
    activeInterval === "Custom"
      ? setArrowContIsShown(false)
      : setArrowContIsShown(true);
    let newArr =
      activeInterval === "3h"
        ? ["hour", "day"]
        : activeInterval === "6h"
        ? ["hour", "day"]
        : activeInterval === "D" || activeInterval === "W"
        ? ["hour", "day"]
        : activeInterval === "1M" || activeInterval === "3M"
        ? ["hour", "day", "month"]
        : activeInterval === "6M"
        ? ["day", "month"]
        : activeInterval === "Y"
        ? ["day", "month", "year"]
        : activeInterval === "YTD" || activeInterval === "Custom"
        ? ["hour", "day", "month", "year"]
        : activeInterval === "Live"
        ? ["hour"]
        : [];
    setGroupArr(newArr);
  }, [activeInterval]);

  useEffect(() => {
    if (props.rangeSelModalFlag) {
      setIsLoading(true);
      props.fetchEnergyData(props.startDateEpoch, props.endDateEpoch);
      setActiveInterval("Custom");
      sessionStorage.setItem("energyInterval", "Custom");
      props.setRangeSelModalFlag(false);
    }
  }, [props.rangeSelModalFlag]);

  useLayoutEffect(() => {
    console.log("Energy chart rendered!!!");
    // console.log(props.data);

    // Disable chart animations
    am4core.options.animationsEnabled = false;

    // Initiate wrapper
    let chart2 = am4core.create("chartdiv2", am4charts.XYChart);

    chart2.paddingRight = 30;
    chart2.paddingLeft = 0;
    chart2.background.opacity = 0;
    chart2.dateFormatter.dateFormat = "dd-MM-yyyy";

    // Initiate data
    let data2 = [];
    chart2.data = props.data
      ? props.data.length === 1
        ? // Empty data case : Show empty interval
          [
            {
              date: new Date(curIntervalStart * 1000),
              import: null,
              export: null,
              pv: null,
            },
            {
              date: new Date(curIntervalEnd * 1000),
              import: null,
              export: null,
              pv: null,
            },
          ]
        : props.data
      : data2;

    // Add incremental data as props.data changes
    // chart.addData(props.data ? props.data : data);

    // Configure mouse left-click
    chart2.mouseWheelBehavior = "zoomX";

    // Big number formatter
    chart2.numberFormatter.numberFormat = "#a";

    // Disable auto resize (possible performance improvement)
    // chart2.svgContainer.autoResize = false;

    // Preloader
    chart2.preloader.background.opacity = 0;
    chart2.preloader.stroke = am4core.color("#00aad0");
    chart2.preloader.strokeWidth = 1;
    chart2.preloader.fontSize = 14;
    chart2.preloader.scale = 0.5;
    chart2.preloader.align = "center";
    chart2.preloader.valign = "middle";

    // Initiate x-axis + options
    let dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.grid.template.location = 0;
    dateAxis2.renderer.labels.template.fill = am4core.color("#efefef");
    dateAxis2.renderer.labels.template.fontSize = 10;
    dateAxis2.title.fontSize = 12;
    dateAxis2.title.text = "Date";
    dateAxis2.title.fill = am4core.color("#efefef");
    dateAxis2.title.align = "right";
    dateAxis2.title.marginTop = props.fs ? 10 : -10;
    dateAxis2.title.marginRight = props.fs ? 0 : -20;
    dateAxis2.groupData = isGrouped;
    dateAxis2.groupInterval = { timeUnit: activeGroupInterval, count: 1 };
    // dateAxis2.groupCount = 200;
    // Enable 10sec accuray for live chart
    if (activeInterval === "Live") {
      dateAxis2.baseInterval = { timeUnit: "second", count: 10 };
    }
    dateAxis2.renderer.cellStartLocation = 0.4;
    dateAxis2.renderer.cellEndLocation = 0.6;

    // Initiate y-axis + options
    let valueAxisY2 = chart2.yAxes.push(new am4charts.ValueAxis());
    valueAxisY2.tooltip.disabled = true;
    valueAxisY2.renderer.minWidth = 35;
    valueAxisY2.renderer.labels.template.fill = am4core.color("#efefef");
    valueAxisY2.renderer.labels.template.fontSize = 10;
    valueAxisY2.title.fontSize = 12;
    valueAxisY2.title.text = "Wh";
    valueAxisY2.title.fill = am4core.color("#efefef");
    valueAxisY2.title.rotation = 0;
    valueAxisY2.title.valign = "top";

    // Initiate data series
    // Create series
    const createSeries = (field, name, color) => {
      let series = chart2.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.name = name;
      series.fill = color;
      // series.fillOpacity = 0.5;
      series.stroke = am4core.color(color);
      series.tooltipText = "{dateX}: [b]{valueY}[/]";
      series.strokeWidth = 8;
      series.width = 100;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(color);

      series.smoothing = "monotoneX";

      series.groupFields.valueY = "sum";

      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      series.columns.template.width = am4core.percent(50);

      // let bullet = series.bullets.push(new am4charts.CircleBullet());
      // bullet.circle.stroke = am4core.color("#fff");
      // bullet.circle.strokeWidth = 1;

      return series;
    };

    let series4 = createSeries("import", "Import", "#2196f3");
    series4Ref.current = series4;
    let series5 = createSeries("export", "Export", "#1de9b6");
    series5Ref.current = series5;
    let series6;
    if (props.dashboardType === "ZEE") {
      series6 = createSeries("pv", "PV Output", "#F2BF2B");
      series6Ref.current = series6;
    }

    // Initiate bullets
    // let bullet = series.bullets.push(new am4charts.CircleBullet());
    // bullet.circle.stroke = am4core.color("#fff");
    // bullet.circle.strokeWidth = 1;

    // Initiate legend
    chart2.legend = new am4charts.Legend();
    chart2.legend.useDefaultMarker = true;
    let marker2 = chart2.legend.markers.template.children.getIndex(0);
    marker2.cornerRadius(4, 4, 4, 4);
    marker2.stroke = am4core.color("#ccc");
    let markerTemplate2 = chart2.legend.markers.template;
    markerTemplate2.width = 12;
    markerTemplate2.height = 12;
    chart2.legend.labels.template.fill = am4core.color("#efefef");
    chart2.legend.labels.template.fontSize = 12;
    chart2.legend.valueLabels.template.fill = am4core.color("#efefef");

    // Initiate cursor
    chart2.cursor = new am4charts.XYCursor();
    chart2.cursor.behavior = "panXY";

    if (props.fs) {
      // Initiate upper scrollbar
      let scrollbarX2 = new am4charts.XYChartScrollbar();
      scrollbarX2.series.push(series4);
      chart2.scrollbarX = scrollbarX2;

      scrollbarX2.minHeight = 5;
      let scrollAxis2 = scrollbarX2.scrollbarChart.xAxes.getIndex(0);
      scrollAxis2.renderer.labels.template.disabled = true;
      scrollAxis2.renderer.grid.template.disabled = true;

      scrollbarX2.startGrip.icon.disabled = true;
      scrollbarX2.endGrip.icon.disabled = true;
      scrollbarX2.startGrip.background.disabled = true;
      scrollbarX2.endGrip.background.disabled = true;
      scrollbarX2.marginTop = 4;
      scrollbarX2.marginBottom = 20;

      let customStartGrip2 = scrollbarX2.startGrip.createChild(
        am4core.Rectangle
      );
      customStartGrip2.width = 8;
      customStartGrip2.height = 8;
      customStartGrip2.fill = am4core.color("#efefef");
      customStartGrip2.rotation = 45;
      customStartGrip2.align = "center";
      customStartGrip2.valign = "middle";

      let customEndGrip2 = scrollbarX2.endGrip.createChild(am4core.Rectangle);
      customEndGrip2.width = 8;
      customEndGrip2.height = 8;
      customEndGrip2.fill = am4core.color("#efefef");
      customEndGrip2.rotation = 45;
      customEndGrip2.align = "center";
      customEndGrip2.valign = "middle";
    }

    // Export menu
    chart2.exporting.menu = new am4core.ExportMenu();
    chart2.exporting.menu.container = document.getElementById("export-wrapper");
    chart2.exporting.menu.items[0].menu.push({
      label: "Custom",
      menu: [
        {
          type: "custom",
          label: "Lastprofil",
          options: {
            callback: function () {
              setIsModal(true);
            },
          },
        },
      ],
    });
    chart2.exporting.menu.align = "right";
    chart2.exporting.menu.verticalAlign = "top";

    // EVENT LISTENERS

    // Handle null/empty data :: Note beforevalidated is different from beforedatavalidated that does not trigger in case of no data
    // chart2.events.on("beforevalidated", (ev) => {
    //   setIsLoading(false);
    //   // check if there's data
    //   console.log(event.target.data);
    //   if (event.target.data.length === 0) {
    //     chart2.closeAllPopups();
    //     chart2.modal.container = document.body;
    //     chart2.openModal("<strong>No data found!</strong>");
    //   }
    // });

    // Chart is rendered flag
    chart2.events.on("datavalidated", () => {
      setIsRendered(true);
    });

    // Hide custom loading spinner when Amcharts native preloader is shown
    chart2.events.on("beforedatavalidated", () => {
      setIsLoading(false);
      if (getDefaultGroup) {
        if (
          activeInterval === "Live" ||
          activeInterval === "3h" ||
          activeInterval === "6h"
        ) {
          setIsGrouped(false);
          setActiveGroupInterval("none");
          sessionStorage.setItem("energyGroup", "none");
        } else if (activeInterval === "D") {
          setIsGrouped(true);
          setActiveGroupInterval("hour");
          sessionStorage.setItem("energyGroup", "hour");
        } else if (
          activeInterval === "W" ||
          activeInterval === "1M" ||
          activeInterval === "YTD"
        ) {
          setIsGrouped(true);
          setActiveGroupInterval("day");
          sessionStorage.setItem("energyGroup", "day");
        } else if (
          activeInterval === "3M" ||
          activeInterval === "6M" ||
          activeInterval === "Y"
        ) {
          setIsGrouped(true);
          setActiveGroupInterval("month");
          sessionStorage.setItem("energyGroup", "month");
        }
      }
    });

    chart2.events.on("ready", () => {
      if (activeInterval === "Live") {
        dateAxis2.zoom({ start: 1 / 15, end: 1.01 }, true, true);
      }
    });

    chart2.legend.itemContainers.template.events.on("hit", function (ev) {
      console.log("Clicked on", ev.target.dataItem.dataContext.name);
      console.log("Clicked on", ev.target.dataItem.dataContext.isHidden);

      let hiddenState = ev.target.dataItem.dataContext.isHidden;

      if (ev.target.dataItem.dataContext.name == "Import") {
        sessionStorage.setItem("energyLegendImport", hiddenState);
      }
      if (ev.target.dataItem.dataContext.name == "Export") {
        sessionStorage.setItem("energyLegendExport", hiddenState);
      }
      if (ev.target.dataItem.dataContext.name == "PV Output") {
        sessionStorage.setItem("energyLegendPV", hiddenState);
      }
    });

    series4.events.on("inited", () => {
      sessionStorage.getItem("energyLegendImport") == "true"
        ? series4.show()
        : series4.hide();
    });

    series5.events.on("inited", () => {
      sessionStorage.getItem("energyLegendExport") == "true"
        ? series5.show()
        : series5.hide();
    });

    if (props.dashboardType === "ZEE") {
      series6.events.on("inited", () => {
        sessionStorage.getItem("energyLegendPV") == "true"
          ? series6.show()
          : series6.hide();
      });
    }

    // VIP :: Assign chart object to chartRef2 to be able to access chart object from outside useEffect
    chartRef2.current = chart2;

    return () => {
      chart2.dispose();
      setIsRendered(false);
    };
  }, [props.data, props.fs, activeGroupInterval, isGrouped]);

  // useLayoutEffect(() => {
  //   chartRef2.current.data = props.data
  //     ? props.data.length === 1
  //       ? // Empty data case : Show empty interval
  //         [
  //           {
  //             date: new Date(curIntervalStart * 1000),
  //             import: null,
  //             export: null,
  //             pv: null,
  //           },
  //           {
  //             date: new Date(curIntervalEnd * 1000),
  //             import: null,
  //             export: null,
  //             pv: null,
  //           },
  //         ]
  //       : props.data
  //     : chartRef2.current.data;
  // }, [props.data]);

  useEffect(() => {
    chartRef2.current.legend.itemContainers.template.events.on("ready", () => {
      const container = chartContainerRef2.current;
      let legendContainer =
        container.childNodes[0].childNodes[0].childNodes[1].childNodes[1]
          .childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1]
          .childNodes[0];
      let importBox = legendContainer.childNodes[0].childNodes[1].childNodes[0];
      let exportBox = legendContainer.childNodes[1].childNodes[1].childNodes[0];
      let pvBox;
      if (props.dashboardType === "ZEE") {
        pvBox = legendContainer.childNodes[2].childNodes[1].childNodes[0];
      }
      importBox.id = "dash-energy-chart-import-legend-checkbox";
      exportBox.id = "dash-energy-chart-export-legend-checkbox";
      if (props.dashboardType === "ZEE") {
        pvBox.id = "dash-energy-chart-pv-legend-checkbox";
      }
    });
  });

  const convertDateToEpoch = (date) => {
    return parseInt(date / 1000);
  };

  const handleChangeStartDate = (val) => {
    setStartDate(val);
    setStartDateEpoch(convertDateToEpoch(val));
  };

  const handleChangeEndDate = (val) => {
    setEndDate(val);
    setEndDateEpoch(convertDateToEpoch(val));
  };

  const handleRangeSelectSubmit = () => {
    if (startDateEpoch >= endDateEpoch) {
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else if (isNaN(startDateEpoch) || isNaN(endDateEpoch)) {
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else {
      setIsLoading(true);
      props.fetchEnergyData(startDateEpoch, endDateEpoch);
      setRsMessage("");
      setActiveInterval("Custom");
      sessionStorage.setItem("energyInterval", "Custom");
    }
  };

  const handleIntervalSubmit = (interval) => {
    if (activeInterval !== interval) {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let span =
        interval === "D"
          ? 1
          : interval === "W"
          ? 6
          : interval === "1M"
          ? 30
          : interval === "3M"
          ? 90
          : interval === "6M"
          ? 180
          : interval === "Y"
          ? 365
          : 0;
      let intervalEnd = new Date(new Date().setDate(today.getDate() - span));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);

      props.fetchEnergyData(intervalEndEpoch, todayEpoch);
      setIsLoading(true);
      setShowRS(false);
      if (interval === "D") {
        setActiveInterval("D");
        sessionStorage.setItem("energyInterval", "D");
      } else if (interval === "W") {
        setActiveInterval("W");
        sessionStorage.setItem("energyInterval", "W");
      } else if (interval === "1M") {
        setActiveInterval("1M");
        sessionStorage.setItem("energyInterval", "1M");
      } else if (interval === "3M") {
        setActiveInterval("3M");
        sessionStorage.setItem("energyInterval", "3M");
      } else if (interval === "6M") {
        setActiveInterval("6M");
        sessionStorage.setItem("energyInterval", "6M");
      } else if (interval === "Y") {
        setActiveInterval("Y");
        sessionStorage.setItem("energyInterval", "Y");
      }
      setCurIntervalEnd(todayEpoch);
      setCurIntervalStart(intervalEndEpoch);
    }
  };

  const handleIntervalSubmitHour = (hour) => {
    if (activeInterval !== hour) {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let span = hour === "3h" ? 3 : hour === "6h" ? 6 : hour === "1h" ? 1 : 0;
      let intervalEnd = new Date(new Date().setHours(today.getHours() - span));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      props.fetchEnergyData(intervalEndEpoch, todayEpoch);
      setIsLoading(true);
      setShowRS(false);
      if (hour === "1h") {
        setActiveInterval("Live");
        sessionStorage.setItem("energyInterval", "Live");
      } else if (hour === "3h") {
        setActiveInterval("3h");
        sessionStorage.setItem("energyInterval", "3h");
      } else if (hour === "6h") {
        setActiveInterval("6h");
        sessionStorage.setItem("energyInterval", "6h");
      }
      setCurIntervalEnd(todayEpoch);
      setCurIntervalStart(intervalEndEpoch);
    }
  };

  const handleYTDSubmit = () => {
    if (activeInterval !== "YTD") {
      let today = new Date();
      console.log(today);
      let todayEpoch = parseInt(today / 1000);
      console.log(todayEpoch);
      let curYear = new Date().getFullYear();
      let intervalEnd = new Date(`01/01/${curYear}`);
      // intervalEnd.setHours(intervalEnd.getHours() + 24);
      console.log(intervalEnd);
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      console.log(intervalEndEpoch);
      props.fetchEnergyData(intervalEndEpoch, todayEpoch);
      setIsLoading(true);
      setShowRS(false);
      setActiveInterval("YTD");
      sessionStorage.setItem("energyInterval", "YTD");
    }
  };

  const handleArrowBack = () => {
    setArrowForwardIsDisabled(false);
    setArrowFFIsDisabled(false);
    if (activeInterval.includes("h")) {
      let curStart = new Date(curIntervalStart * 1000);
      let span = activeInterval === "3h" ? 3 : activeInterval === "6h" ? 6 : 0;
      let intervalStart = new Date(
        curStart.setHours(curStart.getHours() - span)
      );
      let intervalStartEpoch = parseInt(intervalStart / 1000);

      props.fetchEnergyData(intervalStartEpoch, curIntervalStart);
      setIsLoading(true);
      setCurIntervalStart(intervalStartEpoch);
      setCurIntervalEnd(curIntervalStart);
    } else {
      let curStart = new Date(curIntervalStart * 1000);
      console.log(curStart);
      let span =
        activeInterval === "D"
          ? 1
          : activeInterval === "W"
          ? 6
          : activeInterval === "1M"
          ? 30
          : activeInterval === "3M"
          ? 90
          : activeInterval === "6M"
          ? 180
          : activeInterval === "Y"
          ? 365
          : 0;
      let intervalStart = new Date(curStart.setDate(curStart.getDate() - span));
      let intervalStartEpoch = parseInt(intervalStart / 1000);

      console.log(intervalStart);

      props.fetchEnergyData(intervalStartEpoch, curIntervalStart);
      setIsLoading(true);
      setCurIntervalStart(intervalStartEpoch);
      setCurIntervalEnd(curIntervalStart);
    }
  };

  const handleArrowForward = () => {
    if (activeInterval.includes("h")) {
      let curEnd = new Date(curIntervalEnd * 1000);
      let span = activeInterval === "3h" ? 3 : activeInterval === "6h" ? 6 : 0;
      let today = new Date();
      let intervalEndCheck = new Date(
        curEnd.setHours(curEnd.getHours() + span)
      );
      const intervalEnd =
        intervalEndCheck.getTime() < today.getTime() ? intervalEndCheck : today;
      let intervalEndEpoch = parseInt(intervalEnd / 1000);

      // Handle end of data (hour intervals)
      if (intervalEndCheck.getTime() < today.getTime()) {
        setArrowForwardIsDisabled(false);
        setArrowFFIsDisabled(false);
        props.fetchEnergyData(curIntervalEnd, intervalEndEpoch);
        setIsLoading(true);
        setCurIntervalEnd(intervalEndEpoch);
        setCurIntervalStart(curIntervalEnd);
      } else {
        setArrowForwardIsDisabled(true);
        setTimeout(() => {
          setArrowFFIsDisabled(true);
        }, 300);
      }
    } else {
      let curEnd = new Date(curIntervalEnd * 1000);
      let span =
        activeInterval === "D"
          ? 1
          : activeInterval === "W"
          ? 6
          : activeInterval === "1M"
          ? 30
          : activeInterval === "3M"
          ? 90
          : activeInterval === "6M"
          ? 180
          : activeInterval === "Y"
          ? 365
          : 0;
      let today = new Date();
      let intervalEndCheck = new Date(curEnd.setDate(curEnd.getDate() + span));
      const intervalEnd =
        intervalEndCheck.getTime() < today.getTime() ? intervalEndCheck : today;
      let intervalEndEpoch = parseInt(intervalEnd / 1000);

      // Handle end of data (normal intervals)
      if (intervalEndCheck.getTime() < today.getTime()) {
        setArrowForwardIsDisabled(false);
        setArrowFFIsDisabled(false);
        props.fetchEnergyData(curIntervalEnd, intervalEndEpoch);
        setIsLoading(true);
        setCurIntervalEnd(intervalEndEpoch);
        setCurIntervalStart(curIntervalEnd);
      } else {
        setArrowForwardIsDisabled(true);
        setTimeout(() => {
          setArrowFFIsDisabled(true);
        }, 300);
      }
    }
  };

  const handleArrowFastForward = (interval) => {
    if (!arrowFFIsDisabled) {
      if (activeInterval.includes("h")) {
        let today = new Date();
        let todayEpoch = parseInt(today / 1000);
        let span = interval === "3h" ? 3 : interval === "6h" ? 6 : 0;
        let intervalEnd = new Date(
          new Date().setHours(today.getHours() - span)
        );
        let intervalEndEpoch = parseInt(intervalEnd / 1000);

        props.fetchEnergyData(intervalEndEpoch, todayEpoch);
        setIsLoading(true);
        setShowRS(false);
        setCurIntervalEnd(todayEpoch);
        setCurIntervalStart(intervalEndEpoch);
      } else {
        let today = new Date();
        let todayEpoch = parseInt(today / 1000);
        let span =
          interval === "D"
            ? 1
            : interval === "W"
            ? 6
            : interval === "1M"
            ? 30
            : interval === "3M"
            ? 90
            : interval === "6M"
            ? 180
            : interval === "Y"
            ? 365
            : 0;
        let intervalEnd = new Date(new Date().setDate(today.getDate() - span));
        let intervalEndEpoch = parseInt(intervalEnd / 1000);

        props.fetchEnergyData(intervalEndEpoch, todayEpoch);
        setIsLoading(true);
        setShowRS(false);
        setCurIntervalEnd(todayEpoch);
        setCurIntervalStart(intervalEndEpoch);
      }
      setArrowFFIsDisabled(true);
      setTimeout(() => {
        setArrowForwardIsDisabled(true);
      }, 300);
    }
  };

  // Last profil Modal
  const handleChangeLastProfilStartDate = (val) => {
    setLastProfilMsg("");
    if (val && val._isValid) {
      let date = val._d;
      // Current moment
      // const updatedDate = new Date();
      // date.setHours(updatedDate.getHours());
      // date.setMinutes(updatedDate.getMinutes());
      // date.setSeconds(updatedDate.getSeconds());
      // 12 AM
      date.setHours(0, 0, 0, 0);
      setLastProfilStartDate(date);
      setLastProfilStartDateEpoch(convertDateToEpoch(date));
      if (lastProfilEndDateEpoch === convertDateToEpoch(date)) {
        setLastProfilSameDayMsg(
          "Selecting the same day will display the whole day data from 12:00AM until the current time!"
        );
      } else {
        setLastProfilSameDayMsg("");
      }
    } else {
      setLastProfilStartDate(val);
      setLastProfilStartDateEpoch(convertDateToEpoch(val));
      setLastProfilSameDayMsg("");
    }
  };

  const handleChangeLastProfilEndDate = (val) => {
    setLastProfilMsg("");
    if (val && val._isValid) {
      let date = val._d;
      // Current moment
      // const updatedDate = new Date();
      // date.setHours(updatedDate.getHours());
      // date.setMinutes(updatedDate.getMinutes());
      // date.setSeconds(updatedDate.getSeconds());
      // 12 AM
      date.setHours(0, 0, 0, 0);
      setLastProfilEndDate(date);
      setLastProfilEndDateEpoch(convertDateToEpoch(date));
      if (lastProfilStartDateEpoch === convertDateToEpoch(date)) {
        setLastProfilSameDayMsg(
          "Selecting the same day will display the whole day data from 12:00AM until the current time!"
        );
      } else {
        setLastProfilSameDayMsg("");
      }
    } else {
      setLastProfilStartDate(val);
      setLastProfilEndDateEpoch(convertDateToEpoch(val));
      setLastProfilSameDayMsg("");
    }
  };

  const handleCloseModal = () => {
    setIsModal(!isModal);
    setLastProfilStartDate(null);
    setLastProfilEndDate(null);
    setLastProfilStartDateEpoch(0);
    setLastProfilEndDateEpoch(0);
    setLastProfilMsg("");
    setLastProfilFetchLoading(false);
    setLastProfilFetchSuccess(false);
    setLastProfilFetchError(false);
    setLastProfilSameDayMsg("");
  };

  const exportLastProfil = () => {
    if (
      !lastProfilStartDateEpoch ||
      !lastProfilEndDateEpoch ||
      lastProfilStartDateEpoch === 0 ||
      lastProfilEndDateEpoch === 0
    ) {
      setLastProfilMsg("Invalid date input!");
    } else if (lastProfilStartDateEpoch > lastProfilEndDateEpoch) {
      setLastProfilMsg("Invalid range!");
    } else {
      // setLastProfilMsg("Fetching...");
      fetchLastProfil(lastProfilStartDateEpoch, lastProfilEndDateEpoch);
    }
  };

  const fetchLastProfil = (start, end) => {
    const resetLoading = () => {
      setTimeout(() => {
        setLastProfilFetchLoading(false);
        setLastProfilFetchSuccess(false);
        setLastProfilFetchError(false);
      }, 3000);
    };
    setLastProfilFetchLoading(true);
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const deviceId = props.selectedConfigDeviceData.id;
    const head = new Headers();
    head.append("Authorization", "Bearer " + token);

    // Mockoon mock request
    // const url = new URL(
    //   `http://localhost:8002/totalenergy?from=${start}&to=${
    //     start === end ? end + 24 * 60 * 60 : end
    //   }`
    // );

    // Real request
    const url = new URL(
      props.dashboardType === "ZEE"
        ? `./api/v1/devices/${deviceId}/totalenergy?from=${start}&to=${
            start === end ? end + 24 * 60 * 60 : end
          }`
        : `./api/v1/smartmeters/${deviceId}/totalenergy?from=${start}&to=${
            end + 24 * 60 * 60
          }`,
      baseURL
    );

    fetch(url, {
      method: "GET",
      headers: head,
    })
      .then((response) => {
        if (response.status == 401) {
          setLastProfilFetchLoading(false);
          setLastProfilFetchError(true);
          resetLoading();
        }
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        return response.json();
      })
      .then((data) => {
        setLastProfilFetchLoading(false);
        let finalArrEveryMinute = data.map((obj) => {
          return obj.map((obj, index) => {
            if (index === 0) {
              // Convert Epoch time to date, en-GB for DD/MM/YYYY format, dateStyle and timeStyle to remove seconds
              return new Date(obj * 1000)
                .toLocaleString("en-GB", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
                .replace(/,/g, "");
            } else {
              return obj;
            }
          });
        });
        // Select only top of hour readings then every exact 15 minutes
        let finalArrEvery15Mins = finalArrEveryMinute.filter((row) => {
          return (
            row[0].includes(":00") ||
            row[0].includes(":15") ||
            row[0].includes(":30") ||
            row[0].includes(":45")
          );
        });
        // Add labels row in the beginning of array
        props.dashboardType === "ZEE"
          ? finalArrEvery15Mins.unshift([
              "Timestamp",
              "Import Wh",
              "Export Wh",
              "PV Wh",
              "Total Import Wh",
              "Total Export Wh",
              "Total PV Wh",
            ])
          : finalArrEvery15Mins.unshift([
              "Timestamp",
              "Import Wh",
              "Export Wh",
              "Total Import Wh",
              "Total Export Wh",
            ]);
        // Prepare final array to be CSV compatible
        const arrayToCsv = (data) => {
          return data
            .map(
              (row) =>
                row
                  .map(String) // convert every value to String
                  .map((v) => v.replaceAll('"', '""')) // escape double quotes
                  .map((v) => `"${v}"`) // quote it
                  .join(",") // comma-separated
            )
            .join("\r\n"); // rows starting on new lines
        };
        let CSVData = arrayToCsv(finalArrEvery15Mins);
        handleCloseModal();
        const downloadBlob = (content, filename, contentType) => {
          // Create a blob
          var blob = new Blob([content], { type: contentType });
          var url = URL.createObjectURL(blob);

          // Create a link to download it
          var pom = document.createElement("a");
          pom.href = url;
          pom.setAttribute("download", filename);
          pom.click();
        };
        downloadBlob(CSVData, "lastprofil.csv", "text/csv;charset=utf-8;");
      })
      .catch((error) => {
        console.log(error);
        setLastProfilFetchLoading(false);
        setLastProfilFetchError(true);
        resetLoading();
      });
  };

  const JSXGroupBy = (
    <div className='power-chart-rs-wrapper energy-group-by-wrapper'>
      <span className='group-title'>Group by</span>
      <span
        id='dash-energy-chart-fs-group-by-h-sel-btn'
        className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
          activeGroupInterval === "hour" && "active"
        }`}
        style={{ display: groupArr.includes("hour") ? "flex" : "none" }}
        onClick={() => {
          setIsGrouped(true);
          setGetDefaultGroup(false);
          setActiveGroupInterval("hour");
          sessionStorage.setItem("energyGroup", "hour");
        }}>
        h
      </span>
      <span
        id='dash-energy-chart-fs-group-by-d-sel-btn'
        className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
          activeGroupInterval === "day" && "active"
        }`}
        style={{ display: groupArr.includes("day") ? "flex" : "none" }}
        onClick={() => {
          setIsGrouped(true);
          setGetDefaultGroup(false);
          setActiveGroupInterval("day");
          sessionStorage.setItem("energyGroup", "day");
        }}>
        d
      </span>
      <span
        id='dash-energy-chart-fs-group-by-m-sel-btn'
        className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
          activeGroupInterval === "month" && "active"
        }`}
        style={{ display: groupArr.includes("month") ? "flex" : "none" }}
        onClick={() => {
          setIsGrouped(true);
          setGetDefaultGroup(false);
          setActiveGroupInterval("month");
          sessionStorage.setItem("energyGroup", "month");
        }}>
        m
      </span>
      <span
        id='dash-energy-chart-fs-group-by-year-sel-btn'
        className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
          activeGroupInterval === "year" && "active"
        }`}
        style={{ display: groupArr.includes("year") ? "flex" : "none" }}
        onClick={() => {
          setIsGrouped(true);
          setGetDefaultGroup(false);
          setActiveGroupInterval("year");
          sessionStorage.setItem("energyGroup", "year");
        }}>
        y
      </span>
      <OverlayTrigger
        key='raw'
        placement='top'
        overlay={
          <Tooltip id='tooltip-top' className='ytd-tooltip'>
            RAW data
          </Tooltip>
        }>
        <span
          id='dash-energy-chart-fs-group-by-none-sel-btn'
          className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
            activeGroupInterval === "none" && "active"
          }`}
          onClick={() => {
            setIsGrouped(false);
            setGetDefaultGroup(false);
            setActiveGroupInterval("none");
            sessionStorage.setItem("energyGroup", "none");
          }}>
          None
        </span>
      </OverlayTrigger>
    </div>
  );

  const JSXLastprofil = (
    <div id='lastprofil'>
      <div className='lastprofil-row'>
        <span className='lastprofil-title'>From</span>
        <KeyboardDatePicker
          format='DD/MM/yyyy'
          value={lastProfilStartDate}
          onChange={handleChangeLastProfilStartDate}
        />
      </div>
      <div className='lastprofil-row'>
        <span className='lastprofil-title'>To</span>
        <KeyboardDatePicker
          format='DD/MM/yyyy'
          value={lastProfilEndDate}
          onChange={handleChangeLastProfilEndDate}
        />
      </div>
      <div className='lastprofil-row' style={{ marginBottom: "0" }}>
        <span className='lastprofil-title'></span>
        <span style={{ width: "100%", textAlign: "left", fontSize: "12px" }}>
          {lastProfilSameDayMsg}
        </span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {(props.data.length === 0 || isLoading) && (
        <div
          className='subtitle is-6 light-text'
          style={{
            position: "absolute",
            left: "50%",
            top: "45%",
            transform: "translate(-50%,-150%)",
          }}>
          <div id='dash-energy-chart-loading-spinner' className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div
        ref={selectorDiv}
        id='selectordiv'
        className='selector-div-energy'
        style={{
          display:
            props.fs && isRendered
              ? props.isMobile
                ? "flex"
                : "block"
              : "none",
          position: "relative",
        }}>
        {props.isMobile && isRendered ? (
          <Fragment>
            <DropdownButton
              id='chart-button'
              title='Controls'
              variant='outline-light'>
              <Dropdown.Header>Interval</Dropdown.Header>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-live-sel-btn'
                className={activeInterval === "Live" && "active"}
                onClick={() => handleIntervalSubmitHour("1h")}>
                Live
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-3h-sel-btn'
                className={activeInterval === "3h" && "active"}
                onClick={() => handleIntervalSubmitHour("3h")}>
                3h
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-6h-sel-btn'
                className={activeInterval === "6h" && "active"}
                onClick={() => handleIntervalSubmitHour("6h")}>
                6h
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-1d-sel-btn'
                className={activeInterval === "D" && "active"}
                onClick={() => handleIntervalSubmit("D")}>
                D
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-1w-sel-btn'
                className={activeInterval === "W" && "active"}
                onClick={() => handleIntervalSubmit("W")}>
                W
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-1m-sel-btn'
                className={activeInterval === "1M" && "active"}
                onClick={() => handleIntervalSubmit("1M")}>
                1M
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-3m-sel-btn'
                className={activeInterval === "3M" && "active"}
                onClick={() => handleIntervalSubmit("3M")}>
                3M
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-6m-sel-btn'
                className={activeInterval === "6M" && "active"}
                onClick={() => handleIntervalSubmit("6M")}>
                6M
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-1y-sel-btn'
                className={activeInterval === "Y" && "active"}
                onClick={() => handleIntervalSubmit("Y")}>
                1Y
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-ytd-sel-btn'
                className={activeInterval === "YTD" && "active"}
                onClick={handleYTDSubmit}>
                Year-to-Date
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-interval-custom-sel-btn'
                className={activeInterval === "Custom" && "active"}
                onClick={() => props.setShowMobileModalRS(true)}>
                Custom
              </Dropdown.Item>
              <Dropdown.Header>Group by</Dropdown.Header>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-group-by-h-sel-btn'
                className={activeGroupInterval === "hour" && "active"}
                style={{ display: groupArr.includes("hour") ? "flex" : "none" }}
                onClick={() => {
                  setIsGrouped(true);
                  setGetDefaultGroup(false);
                  setActiveGroupInterval("hour");
                  sessionStorage.setItem("energyGroup", "hour");
                }}>
                h
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-group-by-d-sel-btn'
                className={activeGroupInterval === "day" && "active"}
                style={{ display: groupArr.includes("day") ? "flex" : "none" }}
                onClick={() => {
                  setIsGrouped(true);
                  setGetDefaultGroup(false);
                  setActiveGroupInterval("day");
                  sessionStorage.setItem("energyGroup", "day");
                }}>
                d
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-group-by-m-sel-btn'
                className={activeGroupInterval === "month" && "active"}
                style={{
                  display: groupArr.includes("month") ? "flex" : "none",
                }}
                onClick={() => {
                  setIsGrouped(true);
                  setGetDefaultGroup(false);
                  setActiveGroupInterval("month");
                  sessionStorage.setItem("energyGroup", "month");
                }}>
                m
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-group-by-y-sel-btn'
                className={activeGroupInterval === "year" && "active"}
                style={{ display: groupArr.includes("year") ? "flex" : "none" }}
                onClick={() => {
                  setIsGrouped(true);
                  setGetDefaultGroup(false);
                  setActiveGroupInterval("year");
                  sessionStorage.setItem("energyGroup", "year");
                }}>
                y
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-energy-chart-fs-controls-mobile-group-by-none-sel-btn'
                className={activeGroupInterval === "none" && "active"}
                onClick={() => {
                  setIsGrouped(false);
                  setGetDefaultGroup(false);
                  setActiveGroupInterval("none");
                  sessionStorage.setItem("energyGroup", "none");
                }}>
                None
              </Dropdown.Item>
            </DropdownButton>
          </Fragment>
        ) : (
          <Fragment>
            <div className='row row-lg'>
              <div className='col interval-col'>
                <div className='power-chart-rs-wrapper'>
                  <span className='group-title'>
                    Interval
                    <span className='secret-span' style={{ color: "#273541" }}>
                      0
                    </span>
                  </span>
                  <OverlayTrigger
                    key='live'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Live data
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-sel-btn'
                      className={`power-chart-rs-daterange flex-it ${
                        activeInterval === "Live" && "active"
                      }`}
                      onClick={() => handleIntervalSubmitHour("1h")}>
                      Live
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='3h'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last 3 hours
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-3h-sel-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "3h" && "active"
                      }`}
                      onClick={() => handleIntervalSubmitHour("3h")}>
                      3h
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='6h'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last 6 hours
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-6h-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "6h" && "active"
                      }`}
                      onClick={() => handleIntervalSubmitHour("6h")}>
                      6h
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='24h'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last 24 hours
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-1d-sel-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "D" && "active"
                      }`}
                      onClick={() => handleIntervalSubmit("D")}>
                      D
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='1w'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last week
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-1w-sel-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "W" && "active"
                      }`}
                      onClick={() => handleIntervalSubmit("W")}>
                      W
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='1m'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last month
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-1m-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "1M" && "active"
                      }`}
                      onClick={() => handleIntervalSubmit("1M")}>
                      1M
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='3m'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last 3 months
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-3m-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "3M" && "active"
                      }`}
                      onClick={() => handleIntervalSubmit("3M")}>
                      3M
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='6m'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last 6 months
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-6m-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "6M" && "active"
                      }`}
                      onClick={() => handleIntervalSubmit("6M")}>
                      6M
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='1y'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        Last year
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-1y-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange flex-it ${
                        activeInterval === "Y" && "active"
                      }`}
                      onClick={() => handleIntervalSubmit("Y")}>
                      1Y
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key='ytd'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top' className='ytd-tooltip'>
                        From the beginning of current year until today
                      </Tooltip>
                    }>
                    <span
                      id='dash-energy-chart-fs-interval-live-ytd-btn'
                      className={`power-chart-rs-daterange energy-chart-rs-daterange year-to-date-text flex-it ${
                        activeInterval === "YTD" && "active"
                      }`}
                      onClick={handleYTDSubmit}>
                      {props.isTablet ? "YTD" : "Year-to-Date"}
                    </span>
                  </OverlayTrigger>
                  <span
                    id='dash-energy-chart-fs-interval-live-custom-btn'
                    className={`power-chart-rs-daterange energy-chart-rs-daterange custom-selector flex-it ${
                      activeInterval === "Custom" && "active"
                    }
                ${showRS && activeInterval !== "Custom" && "outlined"}`}
                    onClick={() => {
                      setShowRS(!showRS);
                    }}>
                    Custom
                  </span>
                  {showRS && (
                    <div className='date-range-wrapper flex-it'>
                      <div className='flex-it'>
                        <span className='group-title'>From</span>
                        {/* <input className='power-chart-input' type='text' /> */}
                        <KeyboardDatePicker
                          format='DD/MM/yyyy'
                          onChange={handleChangeStartDate}
                          value={startDate}
                          // onFocus={}
                          // onError={handleDateError}
                          // onKeyUp={handleDateSuccess}
                          // inputRef={dateInput}
                          // To disable keyboard input
                          // InputProps={{ readOnly: true }}
                          InputProps={{
                            id: "dash-energy-chart-fs-interval-custom-sel-date-from-input",
                          }}
                          KeyboardButtonProps={{
                            id: "dash-energy-chart-fs-interval-custom-sel-date-from-picker",
                          }}
                        />
                      </div>
                      <div className='flex-it' style={{ marginLeft: "10px" }}>
                        <span className='group-title'>To</span>
                        {/* <input className='power-chart-input' type='text' /> */}
                        <KeyboardDatePicker
                          format='DD/MM/yyyy'
                          onChange={handleChangeEndDate}
                          value={endDate}
                          // onError={handleDateError}
                          // onKeyUp={handleDateSuccess}
                          // inputRef={dateInput}
                          // To disable keyboard input
                          // InputProps={{ readOnly: true }}
                          InputProps={{
                            id: "dash-energy-chart-fs-interval-custom-sel-date-to-input",
                          }}
                          KeyboardButtonProps={{
                            id: "dash-energy-chart-fs-interval-custom-sel-date-to-picker",
                          }}
                        />
                      </div>
                      <button
                        id='dash-energy-chart-fs-interval-custom-sel-date-submit-btn'
                        className='power-chart-rs-submit'
                        onClick={handleRangeSelectSubmit}>
                        <img src={submitArrow} alt='submit' />
                      </button>
                      <p
                        id='dash-energy-chart-fs-interval-custom-sel-date-err-msg'
                        style={{
                          marginLeft: "10px",
                          fontSize: "10px",
                          color: "#f34141",
                          textAlign: "left",
                          width: "60px",
                          lineHeight: "1.2",
                        }}>
                        {rsMessage}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className='col energy-group-by-col'>{JSXGroupBy}</div>
            </div>
            <div className='row row-sml'>
              <div className='col interval-col'>{JSXGroupBy}</div>
            </div>
          </Fragment>
        )}
        <div>
          <div
            className='arrow-controls-wrapper'
            style={{ display: arrowContIsShown ? "flex" : "none" }}>
            <span
              id='dash-energy-chart-fs-interval-arrow-back-btn'
              className='power-chart-rs-daterange energy-chart-rs-daterange flex-it'
              style={{
                background: arrowBackIsDisabled ? "grey" : "#fff",
              }}
              onClick={handleArrowBack}>
              <img src={leftArrow} alt='back arrow' style={{ width: "25px" }} />
            </span>
            <span
              id='dash-energy-chart-fs-interval-arrow-fwd-btn'
              className='power-chart-rs-daterange energy-chart-rs-daterange flex-it'
              style={{
                background: arrowForwardIsDisabled ? "grey" : "#fff",
              }}
              onClick={handleArrowForward}>
              <img
                src={leftArrow}
                alt='forward arrow'
                style={{ width: "25px", transform: "scaleX(-1)" }}
              />
            </span>
            <span
              id='dash-energy-chart-fs-interval-arrow-ffwd-btn'
              className='power-chart-rs-daterange energy-chart-rs-daterange flex-it'
              style={{ background: arrowFFIsDisabled ? "grey" : "#fff" }}
              onClick={() => handleArrowFastForward(activeInterval)}>
              <img
                src={FFArrow}
                alt='forward arrow'
                style={{ width: "25px" }}
              />
            </span>
          </div>
          <OverlayTrigger
            key='export'
            placement='top'
            overlay={
              <Tooltip id='tooltip-top' className='ytd-tooltip'>
                Export
              </Tooltip>
            }>
            <div id='export-wrapper'></div>
          </OverlayTrigger>
        </div>
      </div>
      <div className='amchart-wrapper'>
        <div
          id='chartdiv2'
          ref={chartContainerRef2}
          className={
            props.fs && !showRS
              ? "fullscreen-nodate"
              : props.fs && showRS
              ? "fullscreen-date"
              : ""
          }></div>
      </div>
      <Modal
        title='Export Lastprofil CSV per 15 min'
        content={JSXLastprofil}
        isModal={isModal}
        handleCloseModal={handleCloseModal}
        actionName='Export'
        actionFn={exportLastProfil}
        loading={lastProfilFetchLoading}
        error={lastProfilFetchError}
        success={lastProfilFetchSuccess}
        // disabled={!validateLabel || !validateSN}
        errMsg={lastProfilMsg}
        // actionBtnId='home-add-device-modal-action-btn'
        // cancelBtnId='home-add-device-modal-cancel-btn'
      />
    </Fragment>
  );
};

export default EnergyChart;
