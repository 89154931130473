import React, { useState, useEffect } from "react";
import BaseButton from "../BaseButton/BaseButton";
import "./Modal.css";

const Modal = (props) => {
  const [isModal, setIsModal] = useState(false);

  const active = isModal ? "is-active" : "";

  useEffect(() => {
    setIsModal(props.isModal);
  });

  useEffect(() => {
    const close = (e) => {
      if (props.isModal && e.key === "Escape") {
        props.handleCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [props.isModal]);

  return (
    <div id='custom-modal' className={`modal ${active}`}>
      <div
        className='modal-background'
        style={{ backgroundColor: "rgb(30,39,51,0.86)" }}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{props.title}</p>
          <button
            id='modal-close-btn'
            className='delete'
            aria-label='close'
            onClick={props.handleCloseModal}></button>
        </header>
        <section
          className='modal-card-body'
          style={{ display: "flex", alignItems: "center", minHeight: "90px" }}>
          {props.content}
        </section>
        <footer
          className='modal-card-foot'
          style={{ justifyContent: "flex-end" }}>
          <p
            id='modal-err-msg'
            className='flex-it'
            style={{
              marginBottom: "0",
              marginRight: "1rem",
              fontSize: "11px",
              color: props.isSuccess ? "limegreen" : "red",
            }}>
            {props.errMsg}
          </p>
          <BaseButton
            id={props.actionBtnId}
            classes='button save'
            onClick={props.actionFn}
            text={props.actionName || "Save"}
            loading={props.loading}
            error={props.error}
            success={props.success}
            disabled={props.disabled}
            textColor='#fff'
          />
          <button
            id={props.cancelBtnId}
            className='button'
            onClick={props.handleCloseModal}>
            {props.cancelName || "Cancel"}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
