import React, { Fragment } from "react";
import phase1Icon from "./phase1.svg";
import phase2Icon from "./phase2.svg";
import phase3Icon from "./phase3.svg";
import energyArrow from "./arrow.svg";
import "./PhaseCard.css";
import formatValue from "../../../utils/formatValue";

const PhaseCard = ({
  phaseNumber,
  shortTitle,
  title,
  power,
  apparent,
  voltage,
  current,
  powerFactor,
  deviceIsOnline,
}) => {
  const phaseSelector = [phase1Icon, phase2Icon, phase3Icon];

  return (
    <div id='phase-card-wrapper' className='card-wrapper is-flex'>
      <div className='phase-wrapper' style={{ flexGrow: "1" }}>
        <div
          className='columns py-2 is-vcentered is-mobile is-gapless mb-2 power-readings'
          style={{ height: "100%" }}>
          <div className='column is-size-7 has-text-left phase-titles'>
            POWER
          </div>
          <div className='column'>
            <p
              id={`dash-phase-card-active-power-reading-desktop-L${
                phaseNumber + 1
              }`}
              className={`is-size-7-tablet is-size-6-desktop is-size-4-fullhd has-text-left active-power-reading ${
                deviceIsOnline ? "has-text-white" : "light-text"
              }`}>
              {formatValue(power, "W")}
            </p>
            <p
              id={`dash-phase-card-apparent-power-reading-desktop-L${
                phaseNumber + 1
              }`}
              className={`is-size-12-tablet is-size-10-desktop is-size-6-fullhd has-text-left volt-ampere ${
                deviceIsOnline ? "has-text-white" : "light-text"
              }`}>
              {formatValue(apparent, "VA")}
            </p>
          </div>
        </div>
        <div
          className='columns py-2 is-vcentered is-mobile is-gapless'
          style={{ height: "100%" }}>
          <div
            className='column is-flex phase-titles'
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              height: "90%",
            }}>
            <p className='is-size-7 light-text is-uppercase has-text-left bottom-title'>
              Voltage
            </p>
            <p className='is-size-7 light-text is-uppercase has-text-left bottom-title'>
              Current
            </p>
            <p className='is-size-7 light-text is-uppercase has-text-left bottom-title'>
              PF
            </p>
          </div>
          <div
            className='column is-flex'
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              height: "90%",
            }}>
            {/* Power Readings Mobile */}
            <div className='power-readings-mobile'>
              <p
                id={`dash-phase-card-active-power-reading-mobile-L${
                  phaseNumber + 1
                }`}
                className={`is-size-7-tablet is-size-6-desktop is-size-4-fullhd has-text-left active-power-reading ${
                  deviceIsOnline ? "has-text-white" : "light-text"
                }`}>
                {formatValue(power, "W")}
              </p>
              <p
                id={`dash-phase-card-apparent-power-reading-mobile-L${
                  phaseNumber + 1
                }`}
                className={`is-size-12-tablet is-size-10-desktop is-size-6-fullhd has-text-left volt-ampere ${
                  deviceIsOnline ? "has-text-white" : "light-text"
                }`}>
                {formatValue(apparent, "VA")}
              </p>
            </div>
            <p
              id={`dash-phase-card-voltage-reading-L${phaseNumber + 1}`}
              className={`is-size-6 has-text-left ${
                deviceIsOnline ? "has-text-white" : "light-text"
              }`}>
              {voltage} V
            </p>
            <p
              id={`dash-phase-card-current-reading-L${phaseNumber + 1}`}
              className={`is-size-6 has-text-left ${
                deviceIsOnline ? "has-text-white" : "light-text"
              }`}>
              {current} A
            </p>
            <p
              id={`dash-phase-card-PF-reading-L${phaseNumber + 1}`}
              className={`is-size-6 has-text-left ${
                deviceIsOnline ? "has-text-white" : "light-text"
              }`}>
              {powerFactor}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhaseCard;
