import React, { useState, useEffect } from "react";
import "./DateTime.css";

const DateTime = () => {
  const [timeSec, setTimeSec] = useState(new Date().toLocaleTimeString());
  const [day, setDay] = useState("");
  const [date, setDate] = useState("");
  const [timeMin, setTimeMin] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let changeMin = setInterval(() => {
      let d = new Date();
      setTimeSec(d.toLocaleTimeString());
    }, 1000);
    return () => {
      clearInterval(changeMin);
    };
  }, []);

  useEffect(() => {
    let changeDate = setInterval(() => {
      let d = new Date();
      let formatter = Intl.DateTimeFormat("en-GB", {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let datetime = formatter.format(d);
      let result;
      result = datetime.split(",");
      setDay(result[0]);
      setDate(result[1]);
      const timeSplit = result[2].split(":");
      const hourSplit = timeSplit[0];
      const minuteSplit = timeSplit[1];
      const finalHourSplit = hourSplit === " 0" ? "12" : hourSplit;
      setTimeMin(finalHourSplit + ":" + minuteSplit.toUpperCase());
      setLoading(false);
    }, 1000);

    return () => {
      clearInterval(changeDate);
    };
  }, []);

  return loading ? (
    <div className='column'>
      <div id='dash-date-card-loading-spinner' className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div className='date-time-wrapper'>
      <div className='column upper'>
        <span id='dash-date-card-day-reading' className='date-day'>
          {day}&nbsp;
        </span>
        <span id='dash-date-card-full-date-reading' className='date-fulldate'>
          {date}
        </span>
      </div>
      <div className='column lower'>
        <span id='dash-date-card-time-reading' className='date-time'>
          {timeMin}
        </span>
      </div>
    </div>
  );
};

export default DateTime;
