import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  Fragment,
} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { KeyboardDatePicker } from "@material-ui/pickers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Dropdown, DropdownButton } from "react-bootstrap";
import submitArrow from "../../../images/dashboard/enter_arrow.svg";
import leftArrow from "../../../images/dashboard/arrow_left.svg";
import FFArrow from "../../../images/dashboard/arrow_ff.svg";
import "./PowerChart.css";

am4core.useTheme(am4themes_animated);

const PowerChart = (props) => {
  const [isRendered, setIsRendered] = useState(false);
  const [BGFillTogglerBG, setBGFillTogglerBG] = useState("");
  const [BGFillTogglerColor, setBGFillTogglerColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDateEpoch, setStartDateEpoch] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [endDateEpoch, setEndDateEpoch] = useState(0);
  const [rsMessage, setRsMessage] = useState("");
  const [activeInterval, setActiveInterval] = useState("W");
  const [showRS, setShowRS] = useState(false);
  // This current interval start will be the end interval on clicking arrow back
  const [curIntervalStart, setCurIntervalStart] = useState(null);
  // This current interval end will be the start interval on clicking arrow forward
  const [curIntervalEnd, setCurIntervalEnd] = useState(null);
  const [arrowContIsShown, setArrowContIsShown] = useState(true);
  const [arrowForwardIsDisabled, setArrowForwardIsDisabled] = useState(true);
  const [arrowBackIsDisabled, setArrowBackIsDisabled] = useState(false);
  const [arrowFFIsDisabled, setArrowFFIsDisabled] = useState(true);

  const chartRef = useRef(null);
  const series1Ref = useRef(null);
  const series2Ref = useRef(null);
  const series3Ref = useRef(null);
  const selectorDiv = useRef(null);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    console.log("<Power /> comp rendered!");
  }, []);

  useEffect(() => {
    console.log("props.data changed in <Power />!");
  }, [props.data]);

  useEffect(() => {
    console.log(
      "🚀 ~ file: PowerChart.js ~ line 59 ~ useEffect ~ powerChartLiveData",
      props.powerChartLiveData
    );
    activeInterval === "Live" &&
      chartRef.current.addData(props.powerChartLiveData, 1);
  }, [props.powerChartLiveData]);

  useEffect(() => {
    let today = new Date();
    let todayEpoch = parseInt(today / 1000);
    let oneWeekBefore = new Date(new Date().setDate(today.getDate() - 6));
    let oneWeekBeforeEpoch = parseInt(oneWeekBefore / 1000);
    setCurIntervalStart(oneWeekBeforeEpoch);
    setCurIntervalEnd(todayEpoch);
  }, []);

  // This was used to reset BG fill on fullscreen toggle
  // Now, we remember legend and bg fill
  // useEffect(() => {
  //   setBGFillTogglerBG("#fff");
  //   setBGFillTogglerColor("#333");
  // }, [props.fs]);

  useEffect(() => {
    if (sessionStorage.getItem("powerInterval")) {
      setActiveInterval(sessionStorage.getItem("powerInterval"));
    }
  }, [props.fs]);

  useEffect(() => {
    activeInterval === "Live" ||
    activeInterval === "YTD" ||
    activeInterval === "Custom"
      ? setArrowContIsShown(false)
      : setArrowContIsShown(true);
  }, [activeInterval]);

  useEffect(() => {
    if (props.rangeSelModalFlag) {
      setIsLoading(true);
      props.fetchPowerData(props.startDateEpoch, props.endDateEpoch);
      setActiveInterval("Custom");
      sessionStorage.setItem("powerInterval", "Custom");
      props.setRangeSelModalFlag(false);
    }
  }, [props.rangeSelModalFlag]);

  useLayoutEffect(() => {
    console.log("Power chart rendered!!!");

    // Enable/Disable chart animations
    am4core.options.animationsEnabled = true;

    // Initiate wrapper
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    chart.paddingRight = 30;
    chart.paddingLeft = 0;
    chart.background.opacity = 0;
    chart.dateFormatter.dateFormat = "dd-MM-yyyy";

    // Initiate data
    let data = [];
    chart.data = props.data
      ? props.data.length === 1
        ? // Empty data case : Show empty interval
          [
            {
              date: new Date(curIntervalStart * 1000),
              import: null,
              export: null,
              pv: null,
            },
            {
              date: new Date(curIntervalEnd * 1000),
              import: null,
              export: null,
              pv: null,
            },
          ]
        : props.data
      : data;

    // Add incremental data as props.data changes
    // chart.addData(props.data ? props.data : data);

    // Configure mouse left-click
    chart.mouseWheelBehavior = "zoomX";

    // Big number formatter
    chart.numberFormatter.numberFormat = "#a";

    // Disable auto resize (possible performance improvement)
    // chart.svgContainer.autoResize = false;

    // Preloader
    chart.preloader.background.opacity = 0;
    chart.preloader.stroke = am4core.color("#00aad0");
    chart.preloader.strokeWidth = 1;
    chart.preloader.fontSize = 14;
    chart.preloader.scale = 0.5;
    chart.preloader.align = "center";
    chart.preloader.valign = "middle";

    // Initiate x-axis + options
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.fill = am4core.color("#efefef");
    dateAxis.renderer.labels.template.fontSize = 10;
    dateAxis.title.fontSize = 12;
    dateAxis.title.text = "Date";
    dateAxis.title.fill = am4core.color("#efefef");
    dateAxis.title.align = "right";
    dateAxis.title.marginTop = props.fs ? 10 : -10;
    dateAxis.title.marginRight = props.fs ? 0 : -20;
    // Enable 10sec accuray for live chart
    if (activeInterval === "Live") {
      dateAxis.baseInterval = { timeUnit: "second", count: 10 };
    }
    // Chart internal grouping (replaced by backend grouping)
    // dateAxis.groupData = true;
    // dateAxis.groupCount = 3000;

    // Initiate y-axis + options
    let valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.tooltip.disabled = true;
    valueAxisY.renderer.minWidth = 35;
    valueAxisY.renderer.labels.template.fill = am4core.color("#efefef");
    valueAxisY.renderer.labels.template.fontSize = 10;
    valueAxisY.title.fontSize = 12;
    valueAxisY.title.text = "W";
    valueAxisY.title.fill = am4core.color("#efefef");
    valueAxisY.title.rotation = 0;
    valueAxisY.title.valign = "top";

    // Initiate data series
    // Create series
    const createSeries = (field, name, color) => {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.name = name;
      series.fill = color;
      series.fillOpacity =
        sessionStorage.getItem("powerBGFill") == "true" ? 0.15 : 0;
      series.stroke = am4core.color(color);
      series.tooltipText = "{dateX}: [b]{valueY}[/]";
      series.strokeWidth = 2;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(color);

      series.interpolationDuration = 1000;
      series.defaultState.transitionDuration = 0;
      series.tensionX = 0.8;
      // V.I.P to render data gaps correctly
      series.smoothing = "monotoneX";

      series.groupFields.valueY = "average";

      // Enable bullets for live chart (looking bad for 10sec interval)
      // if (activeInterval === "Live") {
      //   let bullet = series.bullets.push(new am4charts.CircleBullet());
      //   bullet.circle.stroke = am4core.color("#fff");
      //   bullet.circle.strokeWidth = 0;
      // }

      return series;
    };

    let series1 = createSeries("import", "Import", "#2196f3");
    series1Ref.current = series1;
    let series2 = createSeries("export", "Export", "#1de9b6");
    series2Ref.current = series2;
    let series3;
    if (props.dashboardType === "ZEE") {
      series3 = createSeries("pv", "PV Output", "#F2BF2B");
      series3Ref.current = series3;
    }

    // Initiate global bullets
    // let bullet = series.bullets.push(new am4charts.CircleBullet());
    // bullet.circle.stroke = am4core.color("#fff");
    // bullet.circle.strokeWidth = 1;

    // Initialize per series live bullet
    let bullet1 = series1.createChild(am4charts.CircleBullet);
    bullet1.circle.radius = 3;
    bullet1.fillOpacity = 1;
    bullet1.fill = "#2196f3";
    bullet1.isMeasured = false;

    let bullet2 = series2.createChild(am4charts.CircleBullet);
    bullet2.circle.radius = 3;
    bullet2.fillOpacity = 1;
    bullet2.fill = "#1de9b6";
    bullet2.isMeasured = false;

    let bullet3;

    if (props.dashboardType === "ZEE") {
      bullet3 = series3.createChild(am4charts.CircleBullet);
      bullet3.circle.radius = 3;
      bullet3.fillOpacity = 1;
      bullet3.fill = "#F2BF2B";
      bullet3.isMeasured = false;
    }

    if (activeInterval !== "Live") {
      bullet1.disabled = true;
      bullet2.disabled = true;
      if (bullet3) {
        bullet3.disabled = true;
      }
    }

    // Initiate legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(4, 4, 4, 4);
    marker.stroke = am4core.color("#ccc");
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    chart.legend.labels.template.fill = am4core.color("#efefef");
    chart.legend.labels.template.fontSize = 12;
    chart.legend.valueLabels.template.fill = am4core.color("#efefef");

    // Initiate cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";

    if (props.fs) {
      // Initiate upper scrollbar
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series1);
      chart.scrollbarX = scrollbarX;

      scrollbarX.minHeight = 5;
      let scrollAxis = scrollbarX.scrollbarChart.xAxes.getIndex(0);
      scrollAxis.renderer.labels.template.disabled = true;
      scrollAxis.renderer.grid.template.disabled = true;

      scrollbarX.startGrip.icon.disabled = true;
      scrollbarX.endGrip.icon.disabled = true;
      scrollbarX.startGrip.background.disabled = true;
      scrollbarX.endGrip.background.disabled = true;
      scrollbarX.marginTop = 4;
      scrollbarX.marginBottom = 20;

      let customStartGrip = scrollbarX.startGrip.createChild(am4core.Rectangle);
      customStartGrip.width = 8;
      customStartGrip.height = 8;
      customStartGrip.fill = am4core.color("#efefef");
      customStartGrip.rotation = 45;
      customStartGrip.align = "center";
      customStartGrip.valign = "middle";

      let customEndGrip = scrollbarX.endGrip.createChild(am4core.Rectangle);
      customEndGrip.width = 8;
      customEndGrip.height = 8;
      customEndGrip.fill = am4core.color("#efefef");
      customEndGrip.rotation = 45;
      customEndGrip.align = "center";
      customEndGrip.valign = "middle";
    }

    // Export menu
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.container = document.getElementById("export-wrapper");
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";

    // EVENT LISTENERS

    // Chart is rendered flag
    chart.events.on("datavalidated", () => {
      setIsRendered(true);
      // Change date axis zoom for adding live data
      if (activeInterval === "Live") {
        // Fixed Y-axis no matter what
        // valueAxisY.min = valueAxisY.minZoomed;
        // valueAxisY.max = valueAxisY.maxZoomed;
        // Zoom to last 5 mins
        dateAxis.zoomToDates(
          new Date(new Date().setMinutes(new Date().getMinutes() - 30)),
          new Date()
        );
        // Zoom out to show new data
        // dateAxis.zoom({ start: 1 / 15, end: 1.2 }, false, true);
        dateAxis.keepSelection = true;
      }
    });

    dateAxis.events.on("validated", () => {
      am4core.iter.each(dateAxis.renderer.labels.iterator(), (label) => {
        label.fillOpacity = label.fillOpacity;
      });
    });

    // Hide custom loading spinner when Amcharts native preloader is shown
    chart.events.on("beforedatavalidated", (ev) => {
      setIsLoading(false);
      if (sessionStorage.getItem("powerBGFill") == "true") {
        setBGFillTogglerBG("#3f9eff");
        setBGFillTogglerColor("#efefef");
      } else {
        setBGFillTogglerBG("#fff");
        setBGFillTogglerColor("#333");
      }
    });

    chart.legend.itemContainers.template.events.on("hit", (ev) => {
      console.log("Clicked on", ev.target.dataItem.dataContext.name);
      console.log("Clicked on", ev.target.dataItem.dataContext.isHidden);

      let hiddenState = ev.target.dataItem.dataContext.isHidden;

      if (ev.target.dataItem.dataContext.name == "Import") {
        sessionStorage.setItem("powerLegendImport", hiddenState);
      }
      if (ev.target.dataItem.dataContext.name == "Export") {
        sessionStorage.setItem("powerLegendExport", hiddenState);
      }
      if (ev.target.dataItem.dataContext.name == "PV Output") {
        sessionStorage.setItem("powerLegendPV", hiddenState);
      }
    });

    series1.events.on("inited", () => {
      sessionStorage.getItem("powerLegendImport") == "true"
        ? series1.show()
        : series1.hide();
    });

    series2.events.on("inited", () => {
      sessionStorage.getItem("powerLegendExport") == "true"
        ? series2.show()
        : series2.hide();
    });

    series1.events.on("validated", () => {
      if (props.data.length !== 0) {
        bullet1.moveTo(series1.dataItems.last.point);
        bullet1.validatePosition();
      }
    });

    series2.events.on("validated", () => {
      if (props.data.length !== 0) {
        bullet2.moveTo(series2.dataItems.last.point);
        bullet2.validatePosition();
      }
    });

    if (props.dashboardType === "ZEE") {
      series3.events.on("inited", () => {
        sessionStorage.getItem("powerLegendPV") == "true"
          ? series3.show()
          : series3.hide();
      });

      series3.events.on("validated", () => {
        if (props.data.length !== 0) {
          bullet3.moveTo(series3.dataItems.last.point);
          bullet3.validatePosition();
        }
      });
    }

    // VIP :: Assign chart object to chartRef to be able to access chart object from outside useEffect
    chartRef.current = chart;

    return () => {
      chart.dispose();
      setIsRendered(false);
    };
  }, [props.data, props.fs]);

  useEffect(() => {
    chartRef.current.legend.itemContainers.template.events.on("ready", () => {
      const container = chartContainerRef.current;
      let legendContainer =
        container.childNodes[0].childNodes[0].childNodes[1].childNodes[1]
          .childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1]
          .childNodes[0];
      let importBox = legendContainer.childNodes[0].childNodes[1].childNodes[0];
      let exportBox = legendContainer.childNodes[1].childNodes[1].childNodes[0];
      let pvBox;
      if (props.dashboardType === "ZEE") {
        pvBox = legendContainer.childNodes[2].childNodes[1].childNodes[0];
      }
      importBox.id = "dash-power-chart-import-legend-checkbox";
      exportBox.id = "dash-power-chart-export-legend-checkbox";
      if (props.dashboardType === "ZEE") {
        pvBox.id = "dash-power-chart-pv-legend-checkbox";
      }
    });
  });

  const handleBGToggle = () => {
    // series1Ref.current.fillOpacity = 0.75;
    const toggleSeries = (series) => {
      if (series.current.fillOpacity !== 0) {
        series.current.fillOpacity = 0;
        setBGFillTogglerBG("#fff");
        setBGFillTogglerColor("#333");
        sessionStorage.setItem("powerBGFill", false);
      } else {
        series.current.fillOpacity = 0.15;
        setBGFillTogglerBG("#3f9eff");
        setBGFillTogglerColor("#efefef");
        sessionStorage.setItem("powerBGFill", true);
      }
    };
    toggleSeries(series1Ref);
    toggleSeries(series2Ref);
    if (props.dashboardType === "ZEE") {
      toggleSeries(series3Ref);
    }
  };

  const convertDateToEpoch = (date) => {
    return parseInt(date / 1000);
  };

  const handleChangeStartDate = (val) => {
    setStartDate(val);
    setStartDateEpoch(convertDateToEpoch(val));
  };

  const handleChangeEndDate = (val) => {
    setEndDate(val);
    setEndDateEpoch(convertDateToEpoch(val));
  };

  const handleRangeSelectSubmit = () => {
    console.log(startDateEpoch, endDateEpoch);
    if (startDateEpoch >= endDateEpoch) {
      console.log("End date must be ahead of start date!");
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else if (isNaN(startDateEpoch) || isNaN(endDateEpoch)) {
      console.log("Invalid date range!");
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else {
      setIsLoading(true);
      props.fetchPowerData(startDateEpoch, endDateEpoch);
      setRsMessage("");
      setActiveInterval("Custom");
      sessionStorage.setItem("powerInterval", "Custom");
    }
  };

  const handleIntervalSubmit = (interval) => {
    if (activeInterval !== interval) {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let span =
        interval === "D"
          ? 1
          : interval === "W"
          ? 6
          : interval === "1M"
          ? 30
          : interval === "3M"
          ? 90
          : interval === "6M"
          ? 180
          : interval === "Y"
          ? 365
          : 0;
      let intervalEnd = new Date(new Date().setDate(today.getDate() - span));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      props.fetchPowerData(intervalEndEpoch, todayEpoch);
      setIsLoading(true);
      setShowRS(false);
      if (interval === "D") {
        setActiveInterval("D");
        sessionStorage.setItem("powerInterval", "D");
      } else if (interval === "W") {
        setActiveInterval("W");
        sessionStorage.setItem("powerInterval", "W");
      } else if (interval === "1M") {
        setActiveInterval("1M");
        sessionStorage.setItem("powerInterval", "1M");
      } else if (interval === "3M") {
        setActiveInterval("3M");
        sessionStorage.setItem("powerInterval", "3M");
      } else if (interval === "6M") {
        setActiveInterval("6M");
        sessionStorage.setItem("powerInterval", "6M");
      } else if (interval === "Y") {
        setActiveInterval("Y");
        sessionStorage.setItem("powerInterval", "Y");
      }
      setCurIntervalEnd(todayEpoch);
      setCurIntervalStart(intervalEndEpoch);
    }
  };

  const handleIntervalSubmitHour = (hour) => {
    if (activeInterval !== hour) {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let span = hour === "3h" ? 3 : hour === "6h" ? 6 : hour === "1h" ? 1 : 0;
      let intervalEnd = new Date(new Date().setHours(today.getHours() - span));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      setIsLoading(true);
      setShowRS(false);
      props.fetchPowerData(intervalEndEpoch, todayEpoch);
      if (hour === "1h") {
        setActiveInterval("Live");
        sessionStorage.setItem("powerInterval", "Live");
      } else if (hour === "3h") {
        setActiveInterval("3h");
        sessionStorage.setItem("powerInterval", "3h");
      } else if (hour === "6h") {
        setActiveInterval("6h");
        sessionStorage.setItem("powerInterval", "6h");
      }
      setCurIntervalEnd(todayEpoch);
      setCurIntervalStart(intervalEndEpoch);
    }
  };

  const handleYTDSubmit = () => {
    if (activeInterval !== "YTD") {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let curYear = new Date().getFullYear();
      let intervalEnd = new Date(`01/01/${curYear}`);
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      props.fetchPowerData(intervalEndEpoch, todayEpoch);
      setIsLoading(true);
      setShowRS(false);
      setActiveInterval("YTD");
      sessionStorage.setItem("powerInterval", "YTD");
    }
  };

  const handleArrowBack = () => {
    setArrowForwardIsDisabled(false);
    setArrowFFIsDisabled(false);
    if (activeInterval.includes("h")) {
      let curStart = new Date(curIntervalStart * 1000);
      let span = activeInterval === "3h" ? 3 : activeInterval === "6h" ? 6 : 0;
      let intervalStart = new Date(
        curStart.setHours(curStart.getHours() - span)
      );
      let intervalStartEpoch = parseInt(intervalStart / 1000);

      props.fetchPowerData(intervalStartEpoch, curIntervalStart);
      setIsLoading(true);
      setCurIntervalStart(intervalStartEpoch);
      setCurIntervalEnd(curIntervalStart);
    } else {
      let curStart = new Date(curIntervalStart * 1000);
      console.log(curStart);
      let span =
        activeInterval === "D"
          ? 1
          : activeInterval === "W"
          ? 6
          : activeInterval === "1M"
          ? 30
          : activeInterval === "3M"
          ? 90
          : activeInterval === "6M"
          ? 180
          : activeInterval === "Y"
          ? 365
          : 0;
      let intervalStart = new Date(curStart.setDate(curStart.getDate() - span));
      let intervalStartEpoch = parseInt(intervalStart / 1000);

      console.log(intervalStart);

      props.fetchPowerData(intervalStartEpoch, curIntervalStart);
      setIsLoading(true);
      setCurIntervalStart(intervalStartEpoch);
      setCurIntervalEnd(curIntervalStart);
    }
  };

  const handleArrowForward = () => {
    if (activeInterval.includes("h")) {
      let curEnd = new Date(curIntervalEnd * 1000);
      let span = activeInterval === "3h" ? 3 : activeInterval === "6h" ? 6 : 0;
      let today = new Date();
      let intervalEndCheck = new Date(
        curEnd.setHours(curEnd.getHours() + span)
      );
      const intervalEnd =
        intervalEndCheck.getTime() < today.getTime() ? intervalEndCheck : today;
      let intervalEndEpoch = parseInt(intervalEnd / 1000);

      // Handle end of data (hour intervals)
      if (intervalEndCheck.getTime() < today.getTime()) {
        setArrowForwardIsDisabled(false);
        setArrowFFIsDisabled(false);
        props.fetchPowerData(curIntervalEnd, intervalEndEpoch);
        setIsLoading(true);
        setCurIntervalEnd(intervalEndEpoch);
        setCurIntervalStart(curIntervalEnd);
      } else {
        setArrowForwardIsDisabled(true);
        setTimeout(() => {
          setArrowFFIsDisabled(true);
        }, 300);
      }
    } else {
      let curEnd = new Date(curIntervalEnd * 1000);
      let span =
        activeInterval === "D"
          ? 1
          : activeInterval === "W"
          ? 6
          : activeInterval === "1M"
          ? 30
          : activeInterval === "3M"
          ? 90
          : activeInterval === "6M"
          ? 180
          : activeInterval === "Y"
          ? 365
          : 0;
      let today = new Date();
      let intervalEndCheck = new Date(curEnd.setDate(curEnd.getDate() + span));
      const intervalEnd =
        intervalEndCheck.getTime() < today.getTime() ? intervalEndCheck : today;
      let intervalEndEpoch = parseInt(intervalEnd / 1000);

      // Handle end of data (normal intervals)
      if (intervalEndCheck.getTime() < today.getTime()) {
        setArrowForwardIsDisabled(false);
        setArrowFFIsDisabled(false);
        props.fetchPowerData(curIntervalEnd, intervalEndEpoch);
        setIsLoading(true);
        setCurIntervalEnd(intervalEndEpoch);
        setCurIntervalStart(curIntervalEnd);
      } else {
        setArrowForwardIsDisabled(true);
        setTimeout(() => {
          setArrowFFIsDisabled(true);
        }, 300);
      }
    }
  };

  const handleArrowFastForward = (interval) => {
    if (!arrowFFIsDisabled) {
      if (activeInterval.includes("h")) {
        let today = new Date();
        let todayEpoch = parseInt(today / 1000);
        let span = interval === "3h" ? 3 : interval === "6h" ? 6 : 0;
        let intervalEnd = new Date(
          new Date().setHours(today.getHours() - span)
        );
        let intervalEndEpoch = parseInt(intervalEnd / 1000);

        props.fetchPowerData(intervalEndEpoch, todayEpoch);
        setIsLoading(true);
        setShowRS(false);
        setCurIntervalEnd(todayEpoch);
        setCurIntervalStart(intervalEndEpoch);
      } else {
        let today = new Date();
        let todayEpoch = parseInt(today / 1000);
        let span =
          interval === "D"
            ? 1
            : interval === "W"
            ? 6
            : interval === "1M"
            ? 30
            : interval === "3M"
            ? 90
            : interval === "6M"
            ? 180
            : interval === "Y"
            ? 365
            : 0;
        let intervalEnd = new Date(new Date().setDate(today.getDate() - span));
        let intervalEndEpoch = parseInt(intervalEnd / 1000);

        props.fetchPowerData(intervalEndEpoch, todayEpoch);
        setIsLoading(true);
        setShowRS(false);
        setCurIntervalEnd(todayEpoch);
        setCurIntervalStart(intervalEndEpoch);
      }
      setArrowFFIsDisabled(true);
      setTimeout(() => {
        setArrowForwardIsDisabled(true);
      }, 300);
    }
  };

  return (
    <Fragment>
      {(props.data.length === 0 || isLoading) && (
        <div
          className='subtitle is-6 light-text'
          style={{
            position: "absolute",
            left: "50%",
            top: "45%",
            transform: "translate(-50%,-150%)",
          }}>
          <div id='dash-power-chart-loading-spinner' className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div
        ref={selectorDiv}
        id='selectordiv'
        className='selector-div-power'
        style={{
          display: props.fs && isRendered ? "flex" : "none",
          alignItems: "center",
          minHeight: "40px",
          position: "relative",
        }}>
        {props.isMobile && isRendered ? (
          <Fragment>
            <DropdownButton
              id='chart-button'
              title='Controls'
              variant='outline-light'>
              <Dropdown.Header>Interval</Dropdown.Header>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-live-sel-btn'
                className={activeInterval === "Live" && "active"}
                onClick={() => handleIntervalSubmitHour("1h")}>
                Live
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-3h-sel-btn'
                className={activeInterval === "3h" && "active"}
                onClick={() => handleIntervalSubmitHour("3h")}>
                3h
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-6h-sel-btn'
                className={activeInterval === "6h" && "active"}
                onClick={() => handleIntervalSubmitHour("6h")}>
                6h
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-1d-sel-btn'
                className={activeInterval === "D" && "active"}
                onClick={() => handleIntervalSubmit("D")}>
                D
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-1w-sel-btn'
                className={activeInterval === "W" && "active"}
                onClick={() => handleIntervalSubmit("W")}>
                W
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-1m-sel-btn'
                className={activeInterval === "1M" && "active"}
                onClick={() => handleIntervalSubmit("1M")}>
                1M
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-3m-sel-btn'
                className={activeInterval === "3M" && "active"}
                onClick={() => handleIntervalSubmit("3M")}>
                3M
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-6m-sel-btn'
                className={activeInterval === "6M" && "active"}
                onClick={() => handleIntervalSubmit("6M")}>
                6M
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-1y-sel-btn'
                className={activeInterval === "Y" && "active"}
                onClick={() => handleIntervalSubmit("Y")}>
                1Y
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-ytd-sel-btn'
                className={activeInterval === "YTD" && "active"}
                onClick={handleYTDSubmit}>
                Year-to-Date
              </Dropdown.Item>
              <Dropdown.Item
                id='dash-power-chart-fs-controls-mobile-interval-custom-sel-btn'
                className={activeInterval === "Custom" && "active"}
                onClick={() => props.setShowMobileModalRS(true)}>
                Custom
              </Dropdown.Item>
            </DropdownButton>
          </Fragment>
        ) : (
          <Fragment>
            <div className='col'>
              <div
                className='power-chart-rs-wrapper'
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}>
                <span className='group-title'>
                  Interval
                  <span className='secret-span' style={{ color: "#273541" }}>
                    0
                  </span>
                </span>
                <OverlayTrigger
                  key='live'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Live data
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-live-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "Live" && "active"
                    }`}
                    onClick={() => handleIntervalSubmitHour("1h")}>
                    Live
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='3h'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last 3 hours
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-3h-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "3h" && "active"
                    }`}
                    onClick={() => handleIntervalSubmitHour("3h")}>
                    3h
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='6h'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last 6 hours
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-6h-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "6h" && "active"
                    }`}
                    onClick={() => handleIntervalSubmitHour("6h")}>
                    6h
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='24h'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last 24 hours
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-1d-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "D" && "active"
                    }`}
                    onClick={() => handleIntervalSubmit("D")}>
                    D
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='1w'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last week
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-1w-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "W" && "active"
                    }`}
                    onClick={() => handleIntervalSubmit("W")}>
                    W
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='1m'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last month
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-1m-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "1M" && "active"
                    }`}
                    onClick={() => handleIntervalSubmit("1M")}>
                    1M
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='3m'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last 3 months
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-3m-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "3M" && "active"
                    }`}
                    onClick={() => handleIntervalSubmit("3M")}>
                    3M
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='6m'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last 6 months
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-6m-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "6M" && "active"
                    }`}
                    onClick={() => handleIntervalSubmit("6M")}>
                    6M
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='1y'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      Last year
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-1y-sel-btn'
                    className={`power-chart-rs-daterange flex-it ${
                      activeInterval === "Y" && "active"
                    }`}
                    onClick={() => handleIntervalSubmit("Y")}>
                    1Y
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  key='ytd'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top' className='ytd-tooltip'>
                      From the beginning of current year until today
                    </Tooltip>
                  }>
                  <span
                    id='dash-power-chart-fs-interval-ytd-sel-btn'
                    className={`power-chart-rs-daterange year-to-date-text flex-it ${
                      activeInterval === "YTD" && "active"
                    }`}
                    onClick={handleYTDSubmit}>
                    {props.isTablet ? "YTD" : "Year-to-Date"}
                  </span>
                </OverlayTrigger>
                <span
                  id='dash-power-chart-fs-interval-custom-sel-btn'
                  className={`power-chart-rs-daterange energy-chart-rs-daterange custom-selector flex-it ${
                    activeInterval === "Custom" && "active"
                  }
                ${showRS && activeInterval !== "Custom" && "outlined"}`}
                  onClick={() => setShowRS(!showRS)}>
                  Custom
                </span>
                {showRS && (
                  <div className='date-range-wrapper flex-it'>
                    <div className='flex-it'>
                      <span className='group-title group-title-from'>From</span>
                      {/* <input className='power-chart-input' type='text' /> */}
                      <KeyboardDatePicker
                        format='DD/MM/yyyy'
                        onChange={handleChangeStartDate}
                        value={startDate}
                        // onFocus={}
                        // onError={handleDateError}
                        // onKeyUp={handleDateSuccess}
                        // inputRef={dateInput}
                        // To disable keyboard input
                        // InputProps={{ readOnly: true }}
                        InputProps={{
                          id: "dash-power-chart-fs-interval-custom-sel-date-from-input",
                        }}
                        KeyboardButtonProps={{
                          id: "dash-power-chart-fs-interval-custom-sel-date-from-picker",
                        }}
                      />
                    </div>
                    <div className='flex-it' style={{ marginLeft: "10px" }}>
                      <span
                        className='group-title'
                        style={{ marginRight: "10px" }}>
                        To
                      </span>
                      {/* <input className='power-chart-input' type='text' /> */}
                      <KeyboardDatePicker
                        format='DD/MM/yyyy'
                        onChange={handleChangeEndDate}
                        value={endDate}
                        // onError={handleDateError}
                        // onKeyUp={handleDateSuccess}
                        // inputRef={dateInput}
                        // To disable keyboard input
                        // InputProps={{ readOnly: true }}
                        InputProps={{
                          id: "dash-power-chart-fs-interval-custom-sel-date-to-input",
                        }}
                        KeyboardButtonProps={{
                          id: "dash-power-chart-fs-interval-custom-sel-date-to-picker",
                        }}
                      />
                    </div>
                    <button
                      id='dash-power-chart-fs-interval-custom-sel-date-submit-btn'
                      className='power-chart-rs-submit'
                      onClick={handleRangeSelectSubmit}>
                      <img src={submitArrow} alt='submit' />
                    </button>
                    <p
                      id='dash-power-chart-fs-interval-custom-sel-date-err-msg'
                      className='rs-msg'
                      style={{
                        marginLeft: "10px",
                        color: "#f34141",
                        whiteSpace: "nowrap",
                      }}>
                      {rsMessage}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
        <div className='col-1'>
          <div>
            <div
              className='arrow-controls-wrapper'
              style={{ display: arrowContIsShown ? "flex" : "none" }}>
              <span
                id='dash-power-chart-fs-interval-arrow-back-btn'
                className='power-chart-rs-daterange energy-chart-rs-daterange flex-it'
                style={{
                  background: arrowBackIsDisabled ? "grey" : "#fff",
                }}
                onClick={handleArrowBack}>
                <img
                  src={leftArrow}
                  alt='back arrow'
                  style={{ width: "25px" }}
                />
              </span>
              <span
                id='dash-power-chart-fs-interval-arrow-fwd-btn'
                className='power-chart-rs-daterange energy-chart-rs-daterange flex-it'
                style={{
                  background: arrowForwardIsDisabled ? "grey" : "#fff",
                }}
                onClick={handleArrowForward}>
                <img
                  src={leftArrow}
                  alt='forward arrow'
                  style={{ width: "25px", transform: "scaleX(-1)" }}
                />
              </span>
              <span
                id='dash-power-chart-fs-interval-arrow-ffwd-btn'
                className='power-chart-rs-daterange energy-chart-rs-daterange flex-it'
                style={{ background: arrowFFIsDisabled ? "grey" : "#fff" }}
                onClick={() => handleArrowFastForward(activeInterval)}>
                <img
                  src={FFArrow}
                  alt='forward arrow'
                  style={{ width: "25px" }}
                />
              </span>
            </div>
            <OverlayTrigger
              key='export'
              placement='top'
              overlay={
                <Tooltip id='tooltip-top' className='ytd-tooltip'>
                  Export
                </Tooltip>
              }>
              <div id='export-wrapper'></div>
            </OverlayTrigger>
          </div>
          <OverlayTrigger
            key='export2'
            placement='top'
            overlay={
              <Tooltip id='tooltip-top' className='ytd-tooltip'>
                Export
              </Tooltip>
            }>
            <div id='export-wrapper'></div>
          </OverlayTrigger>
        </div>
      </div>
      <div className='amchart-wrapper'>
        <div
          id='chartdiv'
          ref={chartContainerRef}
          className={
            props.fs && !showRS
              ? "fullscreen-nodate"
              : props.fs && showRS
              ? "fullscreen-date"
              : ""
          }></div>
        <a
          id='dash-power-chart-bg-fill-btn'
          className='bg-fill-toggler'
          style={{
            display: isRendered ? "block" : "none",
            backgroundColor: BGFillTogglerBG,
            color: BGFillTogglerColor,
            fontSize: "12px",
            padding: "4px",
            border: "1px solid #dbdbdb",
            marginLeft: props.fs ? "0" : "-4px",
          }}
          onClick={handleBGToggle}>
          BG Fill
        </a>
      </div>
    </Fragment>
  );
};

export default PowerChart;
