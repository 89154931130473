import React, { useState, useEffect } from "react";
import "./Toggler.css";

const ToggleSwitch = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(props.remote == 1 ? true : false);
  }, [props.remote]);

  useEffect(() => {
    if (props.isActive) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [props.activeMode]);

  const onToggleSwitchChange = () => {
    if (!props.disabled) {
      setIsChecked(!isChecked);
      if (props.mode !== "repeat") {
        props.setRemote(!isChecked);
        props.setActiveMode(props.mode || "power");
        if (!props.isError) {
          props.setSubmitIsDisabled(false);
        }
      } else {
        props.setRepeatFlag(!isChecked);
        props.handleRepeatToggle(!isChecked);
      }

      // Trigger mobile modal open when toggler goes to ON (exists temporarily in ListView)
      if (props.showMobileModal !== undefined && !isChecked) {
        props.setShowMobileModal(true);
        if (props.setRepeatDaysModal !== undefined) {
          props.setRepeatDaysModal(props.repeatDays);
        }
      }
    } else {
      props.setMessage("Click edit button to enable editing first!");
      setTimeout(() => {
        props.setMessage("");
      }, 3000);
    }
  };

  return (
    <div
      className={`ToggleSwitch ToggleSwitch__rounded ${
        props.disabled && "disabled"
      } `}>
      <div id={props.id} className='ToggleSwitch__wrapper'>
        <div
          className={`Slider ${isChecked && "isChecked"} ${
            props.disabled && "disabled"
          }`}
          onClick={onToggleSwitchChange}></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
