import React, { useState, useEffect } from "react";
import Toggler from "../Toggler/Toggler";
import "./VoltageConfig.css";

const VoltageConfig = (props) => {
  const [voltageSP, setVoltageSP] = useState(null);
  const [voltageMin, setVoltageMin] = useState(null);
  const [voltageMax, setVoltageMax] = useState(null);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isErrorSP, setIsErrorSP] = useState(false);
  const [isErrorVmin, setIsErrorVmin] = useState(false);
  const [isErrorVmax, setIsErrorVmax] = useState(false);

  useEffect(() => {
    setMessage("");
    setIsError(false);
    setIsErrorSP(false);
    setIsErrorVmin(false);
    setIsErrorVmax(false);
    if (validateVoltage(props.voltageSP, props.voltageMin, props.voltageMax)) {
      setVoltageSP(props.voltageSP);
      setVoltageMin(props.voltageMin);
      setVoltageMax(props.voltageMax);
    } else {
      setVoltageSP(props.initVoltageSP);
      setVoltageMin(props.initVoltageMin);
      setVoltageMax(props.initVoltageMax);
    }
  }, [props.activeMode, props.isSubmit, props.mapping]);

  useEffect(() => {
    if (props.isSubmit) {
      props.getVoltageConfig(voltageSP, voltageMin, voltageMax);
    }
  }, [props.isSubmit]);

  const validateVoltage = (sp, min, max) => {
    if (
      sp !== "" &&
      sp >= 0 &&
      sp <= 100 &&
      min >= 180 &&
      min <= 260 &&
      max >= 180 &&
      max <= 260 &&
      min < max
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeVoltageSP = (e) => {
    let val = e.target.value.replace(/[^\d]+/gi, "");
    setVoltageSP(val);
    props.getVoltageConfig(val, voltageMin, voltageMax);

    if (val > 0 && val < 100) {
      setMessage("");
      setIsError(false);
      setIsErrorSP(false);
    } else if (val > 100) {
      setMessage("SP cannot be greater than 100%");
      setIsError(true);
      setIsErrorSP(true);
      props.setSubmitIsDisabled(true);
    } else if (val == 100) {
      setMessage("Feed-In to Grid Mode");
      setIsError(false);
      setIsErrorSP(false);
    } else if (val === "0") {
      setMessage("Zero Export Mode");
      setIsError(false);
      setIsErrorSP(false);
    } else if (val === "") {
      setMessage("");
      setIsError(true);
      props.setSubmitIsDisabled(true);
    }

    if (validateVoltage(val, voltageMin, voltageMax)) {
      props.setSubmitIsDisabled(false);
      setIsError(false);
      setIsErrorSP(false);
    }
  };

  const handleSaveVoltageSP = () => {
    if (validateVoltage(voltageSP, voltageMin, voltageMax)) {
      props.getVoltageConfig(voltageSP, voltageMin, voltageMax);
    } else {
      props.getVoltageConfig(
        props.initVoltageSP,
        props.initVoltageMin,
        props.initVoltageMax
      );
    }
  };

  const handleChangeVoltageMin = (e) => {
    let val = e.target.value.replace(/[^\d]+/gi, "");
    setVoltageMin(val);
    props.getVoltageConfig(voltageSP, val, voltageMax);

    if (val !== "") {
      if (val >= 180 && val <= 260) {
        setMessage("");
        setIsError(false);
        setIsErrorVmin(false);
      } else if (val > 260) {
        setMessage("Vmin cannot be greater than 260V");
        setIsError(true);
        setIsErrorVmin(true);
        props.setSubmitIsDisabled(true);
      } else if (val < 180) {
        setMessage("Vmin cannot be less than 180V");
        setIsError(true);
        setIsErrorVmin(true);
        props.setSubmitIsDisabled(true);
      }
      if (voltageMax && val >= voltageMax) {
        setMessage("Vmin cannot be less than or equal Vmax!");
        setIsError(true);
        setIsErrorVmin(true);
        props.setSubmitIsDisabled(true);
      }
      if (validateVoltage(voltageSP, val, voltageMax)) {
        props.setSubmitIsDisabled(false);
        setIsError(false);
        setIsErrorVmin(false);
        setIsErrorVmax(false);
      }
    } else {
      setMessage("");
    }
  };

  const handleSaveVoltageMin = () => {
    if (validateVoltage(voltageSP, voltageMin, voltageMax)) {
      props.getVoltageConfig(voltageSP, voltageMin, voltageMax);
    } else {
      props.getVoltageConfig(
        props.initVoltageSP,
        props.initVoltageMin,
        props.initVoltageMax
      );
    }
  };

  const handleChangeVoltageMax = (e) => {
    let val = e.target.value.replace(/[^\d]+/gi, "");
    setVoltageMax(val);
    props.getVoltageConfig(voltageSP, voltageMin, val);

    if (val !== "") {
      if (val >= 180 && val <= 260) {
        setMessage("");
        setIsError(false);
        setIsErrorVmax(false);
      } else if (val > 260) {
        setMessage("Vmax cannot be greater than 260V");
        setIsError(true);
        setIsErrorVmax(true);
        props.setSubmitIsDisabled(true);
      } else if (val < 180) {
        setMessage("Vmax cannot be less than 180V");
        setIsError(true);
        setIsErrorVmax(true);
        props.setSubmitIsDisabled(true);
      }
      if (voltageMin && voltageMin >= val) {
        setMessage("Vmin cannot be less than or equal Vmax!");
        setIsError(true);
        setIsErrorVmax(true);
        props.setSubmitIsDisabled(true);
      }
      if (validateVoltage(voltageSP, voltageMin, val)) {
        props.setSubmitIsDisabled(false);
        setIsError(false);
        setIsErrorVmin(false);
        setIsErrorVmax(false);
      }
    } else {
      setMessage("");
    }
  };

  const handleSaveVoltageMax = () => {
    if (validateVoltage(voltageSP, voltageMin, voltageMax)) {
      props.getVoltageConfig(voltageSP, voltageMin, voltageMax);
    } else {
      props.getVoltageConfig(
        props.initVoltageSP,
        props.initVoltageMin,
        props.initVoltageMax
      );
    }
  };

  return (
    <div className='sub-config voltage-config'>
      <div className='columns'>
        <div className='column'>
          <div className='flex-start'>
            <label className='label lighter-text min-200'>
              VOLTAGE CONTROLLED EXPORT
            </label>
            <Toggler
              id='config-voltage-toggler'
              remote={props.remote}
              setRemote={props.setRemote}
              activeMode={props.activeMode}
              setActiveMode={props.setActiveMode}
              isActive={props.activeMode === "voltage" ? true : false}
              mode='voltage'
              setSubmitIsDisabled={props.setSubmitIsDisabled}
              isError={isError}
            />
          </div>
        </div>
      </div>
      <div className='columns mt-0 pt-0'>
        <div className='column mt-0 pt-0 pr-0'>
          <div className='title-card-wrapper my-2'>
            <div className='title-card flex-start'>VOLTAGE EXPORT SETTINGS</div>
            <hr />
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='col-sm pb-0'>
          <div className='flex-start'>
            <label className='label lighter-text'>VMIN (V)</label>
            <input
              id='config-vmin-input'
              className={`input ${isErrorVmin ? "invalid-input" : ""}`}
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              onChange={handleChangeVoltageMin}
              value={voltageMin}
              onBlur={handleSaveVoltageMin}
            />
          </div>
        </div>
        <div className='col-sm pb-0 vmax-desktop'>
          <div className='flex-end pb-0'>
            <label className='label lighter-text'>VMAX (V)</label>
            <input
              id='config-vmax-input'
              className={`input ${isErrorVmax ? "invalid-input" : ""}`}
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              onChange={handleChangeVoltageMax}
              value={voltageMax}
              onBlur={handleSaveVoltageMax}
            />
          </div>
        </div>
      </div>
      <div className='row vsp-desktop'>
        <div className='flex-start'>
          <label className='label lighter-text'>SETPOINT (%)</label>
          <input
            id='config-voltage-setpoint-input'
            className={`input ${isErrorSP ? "invalid-input" : ""}`}
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            onChange={handleChangeVoltageSP}
            value={voltageSP}
            onBlur={handleSaveVoltageSP}
          />
        </div>
      </div>
      <div className='row vmax-mobile'>
        <div className='flex-start'>
          <label className='label lighter-text'>VMAX (V)</label>
          <input
            id='config-vmax-input-mobile'
            className={`input ${isErrorVmax ? "invalid-input" : ""}`}
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            onChange={handleChangeVoltageMax}
            value={voltageMax}
            onBlur={handleSaveVoltageMax}
          />
        </div>
      </div>
      <div className='row vsp-mobile'>
        <div className='flex-start'>
          <label className='label lighter-text'>SETPOINT (%)</label>
          <input
            id='config-voltage-setpoint-input-mobile'
            className='input'
            type='text'
            inputMode='numeric'
            pattern='[0-9]*'
            onChange={handleChangeVoltageSP}
            value={voltageSP}
            onBlur={handleSaveVoltageSP}
          />
        </div>
      </div>
      <p
        style={{
          color: isError ? "red" : "limegreen",
          fontWeight: "normal",
          textAlign: "left",
          minHeight: "1rem",
          position: "relative",
          top: "2rem",
        }}>
        {message}
      </p>
    </div>
  );
};

export default VoltageConfig;
