import React, { useState, useEffect } from "react";
import Toggler from "../Toggler/Toggler";
import "./PowerConfig.css";

const PowerConfig = (props) => {
  const [powerSP, setPowerSP] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (props.powerSP === "0") {
      setMessage("Zero Export Mode");
    }
    if (props.powerSP == 100) {
      setMessage("Feed-In to Grid Mode");
    }
  }, [props.powerSP]);

  useEffect(() => {
    console.log(props.powerSP);
    if (props.powerSP >= 0 && props.powerSP <= 100) {
      setPowerSP(props.powerSP);
    } else {
      setPowerSP(props.initPowerSP);
    }
  }, [props.activeMode, props.isSubmit, props.mapping]);

  useEffect(() => {
    if (props.isSubmit) {
      props.getPowerConfig(powerSP);
    }
  }, [props.isSubmit]);

  const handleChangePowerSP = (e) => {
    let val = e.target.value.replace(/[^\d]+/gi, "");
    setPowerSP(val);
    props.getPowerConfig(val);

    if (val > 0 && val < 100) {
      setMessage("");
      props.setSubmitIsDisabled(false);
      setIsError(false);
    } else if (val > 100) {
      setMessage("SP cannot be greater than 100%");
      setIsError(true);
      props.setSubmitIsDisabled(true);
    } else if (val == 100) {
      setMessage("Feed-In to Grid Mode");
      setIsError(false);
      props.setSubmitIsDisabled(false);
    } else if (val === "0") {
      setMessage("Zero Export Mode");
      setIsError(false);
      props.setSubmitIsDisabled(false);
    } else if (val === "") {
      setMessage("");
      setIsError(true);
      props.setSubmitIsDisabled(true);
    }
  };

  const handleSavePowerSP = () => {
    if (powerSP >= 0 && powerSP <= 100) {
      props.getPowerConfig(powerSP);
    } else {
      props.getPowerConfig(props.initPowerSP);
      setMessage("");
      setIsError(false);
    }
  };

  return (
    <div className='sub-config power-config'>
      <div className='columns'>
        <div className='column'>
          <div className='flex-start'>
            <label className='label lighter-text min-200'>
              POWER CONTROLLED EXPORT
            </label>
            <Toggler
              id='config-power-toggler'
              remote={props.remote}
              setRemote={props.setRemote}
              activeMode={props.activeMode}
              setActiveMode={props.setActiveMode}
              isActive={props.activeMode === "power" ? true : false}
              mode='power'
              setSubmitIsDisabled={props.setSubmitIsDisabled}
              isError={isError}
            />
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column mt-0 pt-0 pr-0'>
          <div className='title-card-wrapper my-2'>
            <div className='title-card flex-start'>POWER EXPORT SETTINGS</div>
            <hr />
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <div className='row'>
            <div className='flex-start'>
              <label className='label lighter-text'>SETPOINT (%)</label>
              <input
                id='config-power-setpoint-input'
                className={`input ${isError ? "invalid-input" : ""}`}
                type='text'
                inputMode='numeric'
                pattern='[0-9]*'
                onChange={handleChangePowerSP}
                onBlur={handleSavePowerSP}
                value={powerSP}
              />
              <p
                className='power-err-msg-desktop'
                style={{
                  marginLeft: "14px",
                  color: isError ? "red" : "limegreen",
                  fontWeight: "normal",
                }}>
                {message}
              </p>
            </div>
          </div>
          <div className='row'>
            {" "}
            <p
              className='power-err-msg-mobile'
              style={{
                color: isError ? "red" : "limegreen",
                fontWeight: "normal",
                position: "relative",
                top: "2rem",
                minHeight: "22px",
                textAlign: "left",
              }}>
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerConfig;
