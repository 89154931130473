import React, { useState, useEffect, Fragment } from "react";
import baseURL from "../../baseURL";
import BaseButton from "../BaseButton/BaseButton";
import "./Password.css";

const Password = (props) => {
  const [oldPW, setoldPW] = useState("");
  const [newPW, setnewPW] = useState("");
  const [confirmPW, setconfirmPW] = useState("");
  const [messageNewPw, setMessageNewPw] = useState(undefined);
  const [messageConfirmPw, setMessageConfirmPw] = useState(undefined);
  const [isInfoColor, setIsInfoColor] = useState(false);
  const [responseMsg, setResponseMsg] = useState(undefined);
  const [pwInputType1, setPwInputType1] = useState("password");
  const [pwInputType2, setPwInputType2] = useState("password");
  const [pwInputType3, setPwInputType3] = useState("password");

  useEffect(() => {
    if (newPW === confirmPW) {
      props.setNewPw(confirmPW);
    }
  }, [confirmPW]);

  useEffect(() => {
    if (!props.isModalPw || !props.showMobileModal) {
      setoldPW("");
      setnewPW("");
      setconfirmPW("");
      setResponseMsg("");
      setPwInputType1("password");
      setPwInputType2("password");
      setPwInputType3("password");
      props.setPwEditDisabled(true);
    }
  }, [props.isModalPw, props.showMobileModal]);

  const handleShowPw1 = () => {
    if (pwInputType1 === "password") {
      setPwInputType1("text");
    } else {
      setPwInputType1("password");
    }
  };

  const handleShowPw2 = () => {
    if (pwInputType2 === "password") {
      setPwInputType2("text");
    } else {
      setPwInputType2("password");
    }
  };

  const handleShowPw3 = () => {
    if (pwInputType3 === "password") {
      setPwInputType3("text");
    } else {
      setPwInputType3("password");
    }
  };

  return (
    <Fragment>
      <div
        id='modal-content-wrapper'
        className='is-fluid'
        style={{ width: "100%" }}>
        <div className='columns '>
          <div className='column flex-start'>
            <label id={props.userIdAdminOverride && "pw-label-is-admin"}>
              {`${props.userIdAdminOverride ? "Admin" : "Current"} Password`}
            </label>
          </div>
          <div className='column control is-expanded'>
            <input
              id='account-pw-edit-modal-current-pw-input'
              className='input'
              maxLength='20'
              type={pwInputType1}
              autoComplete='off'
              value={oldPW}
              onInput={(e) => {
                setoldPW(e.target.value);
                props.getOldPwFromChild(e.target.value);
                if (e.target.value === "") {
                  setResponseMsg("Password cannot be empty!");
                } else {
                  setResponseMsg(undefined);
                }
                if (props.isVerifyEmail) {
                  if (e.target.value === "") {
                    setResponseMsg("Password cannot be empty!");
                    props.setPwEditDisabled(true);
                  } else if (e.target.value.length < 8) {
                    setResponseMsg("Password should be at least 8 characters!");
                    props.setPwEditDisabled(true);
                  } else {
                    props.setPwEditDisabled(false);
                  }
                }
              }}
            />
            <svg
              id='account-pw-edit-modal-current-pw-eye-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              className='eye-icon eye-icon-account'
              alt='Show password'
              onClick={handleShowPw1}
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z'
                fill={pwInputType1 === "password" ? "#757575" : "#1DE9B6"}
              />
            </svg>
          </div>
          <div className='column'>
            <div className='input-err-msg-wrapper'>
              <p
                id='account-pw-edit-modal-current-pw-err-msg'
                style={{ color: isInfoColor ? "limegreen" : "orangered" }}>
                {responseMsg}
              </p>
            </div>
          </div>
        </div>
        <div className='columns '>
          <div className='column flex-start'>
            <label>New Password</label>
          </div>
          <div className='column control is-expanded'>
            <input
              id='account-pw-edit-modal-new-pw-input'
              className='input'
              type={pwInputType2}
              maxLength='20'
              autoComplete='off'
              value={newPW}
              onInput={(e) => {
                setnewPW(e.target.value);
                setIsInfoColor(false);
                if (confirmPW !== "") {
                  if (e.target.value !== confirmPW) {
                    setMessageConfirmPw("Passwords don't match!");
                    props.setPwEditDisabled(true);
                  } else {
                    setMessageConfirmPw(undefined);
                    props.setPwEditDisabled(false);
                  }
                }
                if (e.target.value.length < 8) {
                  setMessageNewPw("Password should be at least 8 characters!");
                  props.setPwEditDisabled(true);
                } else {
                  setMessageNewPw(undefined);
                }
                if (e.target.value.length == 20) {
                  setMessageNewPw("Password max limit reached!");
                  setIsInfoColor(true);
                }
              }}
              onBlur={() => {
                setMessageNewPw(undefined);
              }}
              onPaste={(e) => {
                if (
                  pwInputType2 === "password" ||
                  pwInputType3 === "password"
                ) {
                  e.preventDefault();
                  setIsInfoColor(false);
                  setMessageNewPw("Please write your password!");
                }
              }}
            />
            <svg
              id='account-pw-edit-modal-new-pw-eye-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              className='eye-icon eye-icon-account'
              alt='Show password'
              onClick={handleShowPw2}
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z'
                fill={pwInputType2 === "password" ? "#757575" : "#1DE9B6"}
              />
            </svg>
          </div>
          <div className='column'>
            <div className='input-err-msg-wrapper'>
              <p
                id='account-pw-edit-modal-new-pw-err-msg'
                style={{ color: isInfoColor ? "limegreen" : "orangered" }}>
                {messageNewPw}
              </p>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column flex-start'>
            <label>Confirm New Password</label>
          </div>
          <div className='column control is-expanded'>
            <input
              id='account-pw-edit-modal-confirm-pw-input'
              className='input'
              type={pwInputType3}
              maxLength='20'
              autoComplete='off'
              value={confirmPW}
              onInput={(e) => {
                setconfirmPW(e.target.value);
                setIsInfoColor(false);
                if (e.target.value !== newPW) {
                  setMessageConfirmPw("Passwords don't match!");
                  props.setPwEditDisabled(true);
                } else if (e.target.value === "") {
                  setMessageConfirmPw("Password cannot be empty!");
                  props.setPwEditDisabled(true);
                } else if (e.target.value.length < 8) {
                  setMessageConfirmPw(
                    "Password should be at least 8 characters!"
                  );
                  props.setPwEditDisabled(true);
                } else {
                  setMessageConfirmPw(undefined);
                  props.setPwEditDisabled(false);
                }
              }}
              onBlur={() => {
                setMessageConfirmPw(undefined);
              }}
              onPaste={(e) => {
                if (
                  pwInputType2 === "password" ||
                  pwInputType3 === "password"
                ) {
                  e.preventDefault();
                  setIsInfoColor(false);
                  setMessageConfirmPw("Please write your password once again!");
                }
              }}
            />
            <svg
              id='account-pw-edit-modal-confirm-pw-eye-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              className='eye-icon eye-icon-account'
              alt='Show password'
              onClick={handleShowPw3}
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 5C7 5 2.73 8.11 1 12.5C2.73 16.89 7 20 12 20C17 20 21.27 16.89 23 12.5C21.27 8.11 17 5 12 5ZM12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5ZM12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z'
                fill={pwInputType3 === "password" ? "#757575" : "#1DE9B6"}
              />
            </svg>
          </div>
          <div className='column'>
            <div className='input-err-msg-wrapper'>
              <p
                id='account-pw-edit-modal-confirm-pw-err-msg'
                style={{ color: isInfoColor ? "limegreen" : "orangered" }}>
                {messageConfirmPw}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Password;
