const formatValue = (value, unit) => {
  const len = Math.ceil(Math.log10(Math.abs(value) + 1))
  if (len <= 3) {
    return value.toFixed(0) + ' ' + unit 
  } else if (len <= 4) {
    return (value / Math.pow(10, 3)).toFixed(2) + ' k' + unit
  } else if (len <= 5) {
    return (value / Math.pow(10, 3)).toFixed(1) + ' k' + unit
  } else if (len <= 6) {
    return (value / Math.pow(10, 3)).toFixed(0) + ' k' + unit
  } else if (len <= 7) {
    return (value / Math.pow(10, 6)).toFixed(2) + ' M' + unit
  } else if (len <= 8) {
    return (value / Math.pow(10, 6)).toFixed(1) + ' M' + unit
  } else {
    return (value / Math.pow(10, 6)).toFixed(0) + ' M' + unit
  }
}
export default formatValue
