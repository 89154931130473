import React, { useState, useEffect } from "react";
import "./ClickRipple.css";

const ClickRipple = ({ event }) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    if (event) {
      console.log(event.target);
      setX(event.clientX - event.target.offsetLeft);
      setY(event.clientY - event.target.offsetTop);
    }
  }, []);

  return <span id='ripples' style={{ left: "50%", top: "50%" }}></span>;
};

export default ClickRipple;
