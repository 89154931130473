import React, { useState, useEffect } from "react";
import PhaseCard from "./PhaseCard/PhaseCard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import formatValue from "../../utils/formatValue";
import "./InstantDataWrapper.css";

const InstantDataWrapper = (props) => {
  const phaseData =
    props.status &&
    props.status.map((phase, index) => {
      return {
        phaseNumber: index,
        shortTitle: "L" + (index + 1),
        title: "Phase " + (index + 1),
        voltage: "" + phase.v,
        current: "" + phase.a,
        powerFactor: "" + phase.pf,
        power: phase.p,
        apparent: phase.q,
      };
    });

  return (
    <div className={props.activePower.length === 0 && " is-vcentered"}>
      {props.activePower.length === 0 ? (
        <div className='column'>
          <div id='dash-instant-data-loading-spinner' className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : props.mode === "totalPower" ? (
        <div className='column' style={{ position: "relative" }}>
          <OverlayTrigger
            key='totalpower'
            placement='top'
            positionTop={300}
            overlay={
              <Tooltip
                id='tooltip-top'
                className={`totalpower-tooltip ${
                  props.activePower < 0 ? "export" : "import"
                }`}>
                {props.activePower < 0 ? "Export" : "Import"}
              </Tooltip>
            }>
            {/* Old dot */}
            {/* <span
              className='direction-dot'
              style={{
                position: "absolute",
                width: "14px",
                height: "14px",
                borderRadius: "50%",
                backgroundColor:
                  props.activePower < 0 ? "aquamarine" : "#E44646",
                top: "0",
                right: "10px",
              }}></span> */}
            {/* New arrow */}
            <svg
              id='dash-load-now-card-power-arrow'
              width='13'
              height='18'
              viewBox='0 0 13 18'
              fill={props.activePower < 0 ? "aquamarine" : "#E44646"}
              className='direction-dot'
              style={{
                position: "absolute",
                top: "0",
                right: props.activePower > 0 ? "6px" : "4px",
                transform: props.activePower > 0 ? "scaleX(1)" : "scaleX(-1)",
                transformOrigin: "center",
              }}
              xmlns='http://www.w3.org/2000/svg'>
              <path d='M2.83074 18C2.3772 17.9972 1.92933 17.895 1.51647 17.7C1.06994 17.4956 0.689507 17.1621 0.4206 16.7392C0.151693 16.3163 0.00568033 15.822 0 15.3153V2.68649C0.00568033 2.17976 0.151693 1.68544 0.4206 1.26256C0.689507 0.83968 1.06994 0.506122 1.51647 0.301722C2.03025 0.0496979 2.60187 -0.0473713 3.16619 0.0215753C3.73052 0.0905219 4.26484 0.322712 4.70827 0.691685L12.074 7.00607C12.3628 7.24487 12.5961 7.54825 12.7564 7.89362C12.9167 8.23899 13 8.61746 13 9.00087C13 9.38429 12.9167 9.76276 12.7564 10.1081C12.5961 10.4535 12.3628 10.7569 12.074 10.9957L4.70827 17.3101C4.17733 17.7572 3.51432 18.0008 2.83074 18Z' />
            </svg>
          </OverlayTrigger>
          <h2
            id='dash-load-now-card-power-reading'
            className={`is-size-3-mobile is-size-3-tablet is-size-3-widescreen mt-1 total-power ${
              props.deviceIsOnline ? "has-text-white" : "light-text"
            }`}>
            {formatValue(Math.abs(props.activePower), "W")}
          </h2>
        </div>
      ) : (
        <div>
          <PhaseCard
            {...phaseData[props.phaseIndex]}
            deviceIsOnline={props.deviceIsOnline}
          />
        </div>
      )}
    </div>
  );
};

export default InstantDataWrapper;
