import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Fragment,
} from "react";
import baseURL from "../../baseURL";
import BaseButton from "../BaseButton/BaseButton";
import Toggler from "../Toggler/Toggler";
import Tabbar from "../Tabbar/Tabbar";
import backArrow from "../../images/home_back_arrow.svg";
import homeIcon from "../../images/navbar_home_icon.svg";

import "./Config.css";

const Config = forwardRef((props, ref) => {
  const [settingsData, setSettingsData] = useState([]);
  const [settingsVal, setSettingsVal] = useState({});
  const [remote, setRemote] = useState(false);
  const [activeMode, setActiveMode] = useState("");
  const [mapping, setMapping] = useState(null);
  const [isMappingError, setIsMappingError] = useState(false);
  const [userPowerConfig, setUserPowerConfig] = useState({});
  const [userTimeConfig, setUserTimeConfig] = useState([]);
  const [userVoltageConfig, setUserVoltageConfig] = useState({});
  const [userFreqConfig, setUserFreqConfig] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  // Open for edit rows - Multi-edit
  const [editedRowsArr, setEditedRowsArr] = useState([]);

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  useImperativeHandle(ref, () => ({
    getDeviceConfig,
  }));

  const submitConfig = async (saveChanges) => {
    console.log("Submit Config func...");
    console.log(userPowerConfig);
    console.log(userTimeConfig);
    console.log(userVoltageConfig);
    console.log(userFreqConfig);
    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const url = new URL(
        `./api/v1/devicesettings/${settingsData.id}`,
        baseURL
      );

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          settings: JSON.stringify({
            remote: remote ? 1 : 0,
            mode: activeMode,
            mapping:
              saveChanges !== "reset" ? Number(mapping) : settingsVal.mapping,
            power:
              saveChanges !== "reset" ? userPowerConfig : settingsVal.power,
            time: saveChanges !== "reset" ? userTimeConfig : settingsVal.time,
            voltage:
              saveChanges !== "reset" ? userVoltageConfig : settingsVal.voltage,
            frequency:
              saveChanges !== "reset" ? userFreqConfig : settingsVal.frequency,
          }),
        }),
      });

      if (!response.ok) {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        // alert("Failed to save settings! Check your connection and try again.");
      } else {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setError(false);
        }, 5000);
        // alert("Settings Saved!");
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 3000);
        props.setRepeatDaysModal([]);
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      console.log(error);
      // alert("Failed to save settings! Check your connection and try again.");
    }

    setIsSubmit(false);
    setSubmitIsDisabled(true);
    getDeviceConfig();
  };

  const checkSubmitDisabled = () => {
    if (remote === true && activeMode === "power") {
      if (
        mapping == null ||
        mapping == 0 ||
        userPowerConfig.setpoint == undefined
      ) {
        return true;
      }
    } else if (remote === true && activeMode === "time") {
      if (mapping == null || mapping == 0 || userTimeConfig.length === 0) {
        return true;
      }
    } else if (remote === true && activeMode === "voltage") {
      if (
        mapping == null ||
        mapping == 0 ||
        userVoltageConfig.setpoint == undefined ||
        userVoltageConfig.vmin == undefined ||
        userVoltageConfig.vmax == undefined
      ) {
        return true;
      }
    } else if (remote === true && activeMode === "frequency") {
      if (
        mapping == null ||
        mapping == 0 ||
        userFreqConfig.setpoint == undefined ||
        userFreqConfig.fmin == undefined ||
        userFreqConfig.fmax == undefined
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setSubmitIsDisabled(true);
  }, [props.selectedConfigDeviceData]);

  useEffect(() => {
    if (isSubmit) {
      submitConfig();
    }
  }, [isSubmit]);

  useEffect(() => {
    props.setAnimExit(false);
  }, []);

  useEffect(() => {
    if (checkSubmitDisabled()) {
      setAlertMessage(
        `Selected mode is ${activeMode.toUpperCase()}. Please check 100% MAPPING input and fill-in ${activeMode.toUpperCase()} required data!`
      );
    } else {
      setAlertMessage("");
    }
  }, [activeMode, submitIsDisabled, checkSubmitDisabled]);

  const getDeviceConfig = async () => {
    if (props.isRetry) {
      props.setFetchRetryLoading(true);
      props.setFetchRetrySuccess(false);
      props.setFetchRetryError(false);
    }
    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);

      const url = new URL(
        `./api/v1/devicesettings/${props.selectedConfigDeviceData.settings}`,
        baseURL
      );

      const response = await fetch(url, {
        method: "GET",
        headers: head,
      });

      if (response.ok) {
        let data = await response.json();
        let settingsVal = JSON.parse(data.settings);
        setSettingsData(data);
        setSettingsVal(settingsVal);
        setMapping(settingsVal.mapping);
        setUserPowerConfig(settingsVal.power);
        setUserFreqConfig(settingsVal.frequency);
        setUserVoltageConfig(settingsVal.voltage);
        setUserTimeConfig(settingsVal.time ? settingsVal.time : []);
        setRemote(settingsVal.remote === 1 ? true : false);
        setActiveMode(settingsVal.mode);
        if (props.isRetry) {
          props.handleCloseModalSettingsAfterSuccess();
        }
      } else {
        props.setIsModalSettings(true);
        if (props.isRetry) {
          props.setFetchRetryLoading(false);
          props.setFetchRetrySuccess(false);
          props.setFetchRetryError(true);
          props.setIsRetry(false);
          setTimeout(() => {
            props.setFetchRetryLoading(false);
            props.setFetchRetrySuccess(false);
            props.setFetchRetryError(false);
          }, 1000);
        }
      }
      // TODO
      // if res === 401
      // setErrMessage(data.error);
    } catch (error) {
      props.setIsModalSettings(true);
      if (props.isRetry) {
        props.setFetchRetryLoading(false);
        props.setFetchRetrySuccess(false);
        props.setFetchRetryError(true);
        props.setIsRetry(false);
        setTimeout(() => {
          props.setFetchRetryLoading(false);
          props.setFetchRetrySuccess(false);
          props.setFetchRetryError(false);
        }, 1000);
      }
      console.log(error);
    }
  };

  const getPowerConfig = (sp) => {
    console.log("Submit Config from Power...");
    let obj = {
      setpoint: Number(sp),
    };
    sp === "" || !Number(sp) ? setUserPowerConfig("") : setUserPowerConfig(obj);
  };

  const getTimeConfig = (val) => {
    console.log("Submit Config from Time...");
    // Very important:: we must create a new array for val.. so that when timetable is not changed, (like when user only enables time toggler and saves)
    // useEffect of line 101 will not detect any change.. so submitConfig() won't run
    // Creating a new array with the same values means data is now changed
    let newArr = [...val];
    setUserTimeConfig(newArr);
  };

  const getVoltageConfig = (sp, min, max) => {
    console.log("Submit Config from Voltage...");
    let obj = {
      setpoint: Number(sp),
      vmin: Number(min),
      vmax: Number(max),
    };
    (sp === "" && min === "" && max === "") ||
    !Number(sp) ||
    !Number(min) ||
    !Number(max)
      ? setUserVoltageConfig("")
      : setUserVoltageConfig(obj);
  };

  const getFreqConfig = (sp, min, max) => {
    console.log("Submit Config from Freq...");
    let obj = {
      setpoint: Number(sp),
      fmin: Number(min),
      fmax: Number(max),
    };
    (sp === "" && min === "" && max === "") ||
    !Number(sp) ||
    !Number(min) ||
    !Number(max)
      ? setUserFreqConfig("")
      : setUserFreqConfig(obj);
  };

  useEffect(() => {
    getDeviceConfig();
  }, [props.selectedConfigDeviceData]);

  useEffect(() => {
    console.log(settingsVal);
  }, [settingsVal]);

  const handleChangeMapping = (e) => {
    let val = e.target.value.replace(/[^\d]+/gi, "");
    setMapping(val);
    setIsMappingError(false);

    if (val > 1000000) {
      setMapping(1000000);
    }
    if (val == 0) {
      setIsMappingError(true);
      setSubmitIsDisabled(true);
    }
    setSubmitIsDisabled(false);
  };

  const alterEditedRowsArr = (index) => {
    const arrCopy = [...editedRowsArr];
    if (arrCopy.includes(index)) {
      const newArr = arrCopy.filter((object) => object != index);
      setEditedRowsArr(newArr);
    } else {
      const newArr = [...new Set([...arrCopy, index])];
      setEditedRowsArr(newArr);
    }
  };

  const JSXRemoteControlEnable = (
    <Fragment>
      <label className='label lighter-text min-200'>
        REMOTE CONTROL ENABLE
      </label>
      <Toggler
        id='config-remote-control-enable-toggler'
        remote={remote}
        setRemote={setRemote}
        setActiveMode={setActiveMode}
        setSubmitIsDisabled={setSubmitIsDisabled}
      />
    </Fragment>
  );

  const JSXSaveConfigRemoteDisabled = (
    <div className='config-save'>
      <BaseButton
        id='config-remote-control-disable-save-changes-btn'
        classes='px-6 has-text-weight-semibold save-changes'
        onClick={() => submitConfig("reset")}
        loading={loading}
        error={error}
        success={success}
        disabled={submitIsDisabled}
        text='SAVE CHANGES'
      />
    </div>
  );

  return (
    <Fragment>
      <div id='config-page'>
        <div className='config-page-title'>
          <div
            id='config-back-to-list-btn'
            className='config-back-btn flex-it'
            onClick={props.handleConfigBack}>
            <img src={backArrow} alt='back' />
            <span className='back-to-list'>BACK TO LIST</span>
            <span className='home-icon-mobile'>
              <img src={homeIcon} alt='home icon' />
            </span>
          </div>
          <span id='config-sub-title'>
            {props.selectedConfigDeviceData.name} Settings
          </span>
        </div>
        <div className='config-page-content'>
          {/* Remote control toggler tablet (768 - 1280px) */}
          <div className='columns'>
            <div className='column ml-2 remote-control-enable-mobile'>
              <div className='flex-start'>
                {JSXRemoteControlEnable}
                {!props.isMobile && !remote && JSXSaveConfigRemoteDisabled}
              </div>
            </div>
          </div>
          {/* Remote control toggler Small mobile (< 768px) */}
          {props.isMobile && !remote && (
            <div className='columns'>{JSXSaveConfigRemoteDisabled}</div>
          )}
          <div className='columns remote-control-enable'>
            {/* Remote control toggler Desktop / Laptop (> 1280px) */}
            <div className='column config-main-label'>
              <div className='flex-start'>
                {JSXRemoteControlEnable}
                {!remote && (
                  <div className='columns'>{JSXSaveConfigRemoteDisabled}</div>
                )}
              </div>
            </div>
            {remote && (
              <Fragment>
                <div className='column ml-2 active-mode'>
                  <div className='flex-start'>
                    <label className='label lighter-text'>ACTIVE MODE</label>
                    <input
                      id='config-active-mode-input'
                      className='input text-uppercase'
                      type='text'
                      disabled={true}
                      value={settingsVal.mode || "N/A"}
                    />
                  </div>
                </div>
                <div className='column ml-2 mapping-percent'>
                  <div className='flex-start'>
                    <label className='label lighter-text'>100% MAPPING</label>
                    <input
                      id='config-mapping-input'
                      className={`input ${
                        isMappingError ? "invalid-input" : ""
                      }`}
                      type='text'
                      inputMode='numeric'
                      pattern='[0-9]*'
                      maxLength='7'
                      onChange={handleChangeMapping}
                      value={mapping}
                    />
                    <span className='watt-unit lighter-text'>W</span>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          {remote && (
            <Fragment>
              <div className='columns'>
                <div className='column'>
                  <div className='flex-start'>
                    <label className='label lighter-text min-200 config-main-label'>
                      REMOTE CONTROL TYPE
                    </label>
                    <p
                      style={{
                        marginLeft: "1rem",
                        fontSize: "12px",
                        textAlign: "left",
                      }}>
                      Enable one mode at a time from the corresponding toggler
                      switch
                    </p>
                  </div>
                </div>
              </div>
              <div className='columns' style={{ overflow: "hidden" }}>
                <div
                  id='tabbar-wrapper-col'
                  className='column'
                  style={{ paddingTop: 0 }}>
                  <div className='flex-start'>
                    <label className='label lighter-text min-200 config-main-label'></label>
                    <Tabbar
                      remote={remote}
                      setRemote={setRemote}
                      activeMode={activeMode}
                      setActiveMode={setActiveMode}
                      initPowerSP={
                        settingsVal.power ? settingsVal.power.setpoint : ""
                      }
                      powerSP={userPowerConfig.setpoint}
                      initTimeTable={settingsVal.time ? settingsVal.time : []}
                      timeTable={userTimeConfig}
                      initVoltageSP={
                        settingsVal.voltage ? settingsVal.voltage.setpoint : ""
                      }
                      initVoltageMin={
                        settingsVal.voltage ? settingsVal.voltage.vmin : ""
                      }
                      initVoltageMax={
                        settingsVal.voltage ? settingsVal.voltage.vmax : ""
                      }
                      voltageSP={userVoltageConfig.setpoint}
                      voltageMin={userVoltageConfig.vmin}
                      voltageMax={userVoltageConfig.vmax}
                      initFreqSP={
                        settingsVal.frequency
                          ? settingsVal.frequency.setpoint
                          : ""
                      }
                      initFreqMin={
                        settingsVal.frequency ? settingsVal.frequency.fmin : ""
                      }
                      initFreqMax={
                        settingsVal.frequency ? settingsVal.frequency.fmax : ""
                      }
                      freqSP={userFreqConfig.setpoint}
                      freqMin={userFreqConfig.fmin}
                      freqMax={userFreqConfig.fmax}
                      getPowerConfig={getPowerConfig}
                      getTimeConfig={getTimeConfig}
                      getVoltageConfig={getVoltageConfig}
                      getFreqConfig={getFreqConfig}
                      isSubmit={isSubmit}
                      setSubmitIsDisabled={setSubmitIsDisabled}
                      showMobileModal={props.showMobileModal}
                      setShowMobileModal={props.setShowMobileModal}
                      repeatDaysModal={props.repeatDaysModal}
                      setRepeatDaysModal={props.setRepeatDaysModal}
                      repeatDaysRecIndexModal={props.repeatDaysRecIndexModal}
                      setRepeatDaysRecIndexModal={
                        props.setRepeatDaysRecIndexModal
                      }
                      isSaved={isSaved}
                      alterEditedRowsArr={alterEditedRowsArr}
                      mapping={mapping}
                    />
                  </div>
                </div>
              </div>
              <div className='columns'>
                <div className='config-save'>
                  <p id='config-save-alert-msg' style={{ color: "red" }}>
                    {alertMessage}
                  </p>
                  <BaseButton
                    id='config-remote-control-enable-save-changes-btn'
                    classes='px-6 has-text-weight-semibold save-changes'
                    onClick={() => setIsSubmit(true)}
                    loading={loading}
                    error={error}
                    success={success}
                    text='SAVE CHANGES'
                    disabled={
                      editedRowsArr.length !== 0 ||
                      submitIsDisabled ||
                      checkSubmitDisabled()
                    }
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
});

export default Config;
